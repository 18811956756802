import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Chip,
  Grid,
  
} from '@mui/material';
import '../../context/priceticker.css';
import { Group } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  paddiing: 0,
  color: theme.themeColor,
  }));
// GraphQL Query
export const GET_LIKES = gql`
  query GetRewards($limit: Int) {
    likes(limit: $limit) {
      _id
      likecount
      avatar
      createdAt
    }
  }
`;

const ChipContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  
  justifyContent: 'center',
  width: '100%',
  margin: '0 auto',
}));

const ChipItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'left',
  margin: '8px',
 
  borderRadius: '10px',
  minWidth: '120px',
  maxWidth: '400px',

  // Apply responsive styles (3-per-row) at or below the 'md' breakpoint (~900px).
  // You can adjust to 'sm' (~600px) or a custom media query if you prefer 768px.
  [theme.breakpoints.down('md')]: {
    flex: '1 1 calc(33.3% - 16px)',
    maxWidth: 'none',
  },
}));

function LikesTable() {
  const { data: likesData, loading: loadingLikes, error: errorLikes } = useQuery(GET_LIKES, {
    variables: { limit: 100000 },
    fetchPolicy: 'network-only',
  });

  // Group and sum likes
  const groupAndSumLikes = (likes) => {
    const grouped = {};

    likes.forEach((like) => {
      const { likecount, avatar } = like;

      if (!grouped[likecount]) {
        grouped[likecount] = {
          avatar, // Use the first avatar in the group
          count: 0,
          total: 0,
        };
      }

      grouped[likecount].count += 1; // Increment the count
      grouped[likecount].total += likecount; // Add to the total sum
    });

    return grouped;
  };

  // Sum all likes
  const sumLikes = (likes) => {
    return likes.reduce((total, like) => total + like.likecount, 0);
  };

  // Grouped data
  const groupedData = Array.isArray(likesData?.likes) ? groupAndSumLikes(likesData.likes) : {};

  // Total likes
  const totalLikes = Array.isArray(likesData?.likes) ? sumLikes(likesData.likes) : 0;

  

  if (loadingLikes) return <CircularProgress />;
  if (errorLikes) return <Typography color="error">Error loading data</Typography>;

  return (

    <>
   <div className="ticker-container2">
  <Link to="/market-watch">
    <Grid container spacing={2}> {/* Grid Container for Overall Structure */}
      <Grid item xs={12}> {/* Grid Item for List Items */}
        <div className="ticker-wrapper2"> {/* Wrap for Scrolling Effect (Optional) */}
        <ChipContainer>
      {Object.entries(groupedData).map(([likecount, group]) => (
        <ChipItem key={likecount}>
          <Chip
            // MUI's size prop can make the chip smaller: size="small"
            size="small"
            icon={
              <img
                src={group.avatar}
                alt="Avatar"
                style={{ width: 24, height: 24, borderRadius: '50%' }}
              />
            }
            label={`Users: ${group.count} | Total: ${group.total}`}
          />
        </ChipItem>
      ))}
    </ChipContainer>
         
        </div>
      </Grid>
    </Grid>
  </Link>
</div>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0 }}>
    
    <Box 
  sx={{ 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "space-between", // Pushes content apart
    width: "100%", // Ensures full width
    marginBottom: 0, 
    gap: 2 
  }}
>
  <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
    Exchange Summary
  </Typography>
  <Typography 
    variant="body1" 
    gutterBottom 
    sx={{  }}
  >
    Total Transactions: <b>{totalLikes}</b>
  </Typography>
</Box>

      <TableContainer component={Paper} sx={{ width: '100%', marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{  }}>
              <TableCell>Avatar</TableCell>
              <TableCell align="right">Like Count</TableCell>
              <TableCell align="right">User Count</TableCell>
              <TableCell align="right">Total Likes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedData).map(([likecount, group]) => (
              <TableRow
                key={likecount}
                sx={{
                  '&:hover': { backgroundColor: '#6fc28b' },
                  '&:nth-of-type(odd)': {  },
                }}
              >
                <TableCell>
                  <img
                    src={group.avatar}
                    alt="Avatar"
                    style={{ width: 30, height: 30, borderRadius: '50%' }}
                  />
                </TableCell>
                <TableCell align="right">{likecount}</TableCell>
                <TableCell align="right">{group.count}</TableCell>
                <TableCell align="right" sx={{fontWeight: 800}}>{group.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    <Box>
    
    </Box>
    </>
  );
}

export default LikesTable;