import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog,
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  Drawer,
    Chip,
    CardMedia,
    Tabs,
    Tab,
    TableContainer,
    CircularProgress,
    Snackbar,
    Button,
    Alert
    
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// ===== Recharts =====
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  Legend
} from 'recharts';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { UserContext } from '../../../context/usercontext';
import { UPDATE_USER_PROF } from '../../../context/gqllogics';
import { GET_USER } from '../../../context/gqllogics';
import { useBag } from '../../../context/BagContext';
import { Close, Refresh } from '@mui/icons-material';
import { RefreshOutlined } from '@mui/icons-material';
import BeanTrackerReports from '../../mods/trackerreport';
import LikesTable from '../beanwatch';



// Image URL for Redeem Image
const RedeemImage = 'https://storage.googleapis.com/app_darkendimg/lilbeanie.png';



  export const GET_CART = gql`
   query GetCart($userID: ObjectId!) {
  carts(
    query: { userID: $userID, isPaid: true }
    sortBy: TIMESTAMP_DESC
  ) {
    userID
    beanies
    isPaid
    productId
    prodowner
    timeStamp
    name
    _id
    price
    imageurl
    quantity
    options {
      size
      color
    }
  }
}

  `;

 

  export const GET_PAID_CART_BY_OWNER = gql`
  query GetPaidCartByOwner($prodowner: ObjectId!) {
    carts(
      query: { prodowner: $prodowner, isPaid: true }
      sortBy: TIMESTAMP_DESC
    ) {
      _id
      name
      price
      quantity
      isPaid
      productId
      prodowner
      timeStamp
      imageurl
      purchaseId
      options {
        size
        color
      }
    }
  }
`;


export const GET_USER_REDEEMS = gql`
  query GetUserRedeems($shopId: ObjectId!) {
    shopredeems(query: { shopId: $shopId, isPaid: false }
    sortBy: TIMESTAMP_DESC ) {
      _id
      userId
      shopId
      beanCount
      isPaid
      timestamp
      name
      uimage
      payout
    }
  }
`;

export const GET_PAID_REDEEMS = gql`
  query GetUserRedeems($shopId: ObjectId!) {
    shopredeems(query: { shopId: $shopId, isPaid: true }
    sortBy: TIMESTAMP_DESC) {
      _id
      userId
      shopId
      beanCount
      isPaid
      timestamp
      name
      uimage
      payout
    }
  }
`;

export const INSERT_CART_MUTATION = gql`
mutation InsertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data) {
    userID
    productId
    price
    category
    imageurl
    name  
    redeemIds
  }
}
`;

export const GET_USERBEAN_REDEEMS = gql`
  query GetDrinkRedeems($userId: ObjectId!) {
    shopredeems(query: { userId: $userId } 
    sortBy: TIMESTAMP_DESC) {
      _id
      userId
      shopId
      beanCount
      isPaid
      timestamp
      name
      uimage
      payout
    }
  }
`;

export const GET_DRINK_REDEEMS = gql`
  query GetDrinkRedeems($shop: ObjectId!) {
    redeems(query: { shop: $shop }
    sortBy: REDEEMEDAT_DESC) {
      _id
      userID
      shop
      redeemedAt
      Title
      uimage
      name
      imageurl
      reward
    }
  }
`;

export const GET_USER_REDEEMS1 = gql`
  query GetUserRedeems($userID: ObjectId!) {
    redeems(query: { userID: $userID }
    sortBy: REDEEMEDAT_DESC) {
      _id
      userID
      shop
      redeemedAt
      Title
      uimage
      name
      reward
      imageurl
    }
  }
`;

// ---------- Sample Data ----------




// Table data for “Campaign Performance”
const campaignRows = [
  { id: 1, campaign: 'ROQ', visitors: 1181, contacts: 217, convRate: 15, leads: 26.9, value: '$78.29' },
  { id: 2, campaign: 'Website Ads', visitors: 998, contacts: 182, convRate: 12, leads: 20.1, value: '$63.21' },
 
];

// ---------- The Dashboard in a FullScreen Dialog ----------
export default function BeanReports({onClose }) {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const [itemsInBag1, setItemsInBag1] = useState([]);
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDialog, setOpenDialog] = useState(true);
  const [payout, setPayout] = useState('');
  const [cashappid, setCashAppId] = useState('');
  const [zelleid, setZelleId] = useState('');
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [open, setOpen] = useState(false);  
  const [selectedItem, setSelectedItem] = useState(null); 
  const {usertype} = user.customData;
  const isCoffeeShop = usertype === 'coffeeShop';
  const { itemsInBag, setItemsInBag } = useBag();
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState(0); // Default to first tab
  const [openDialog1, setOpenDialog1] = useState(false);

    //dialog handlers
    const handleClickOpen = () => {
      setOpenDialog1(true);
    };
  
    const handleClose1 = () => {
      setOpenDialog(false);
    };

const handleTabChange = (event, newValue) => {
  setSelectedTab(newValue);
};

  const handleDrawerOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

    const navigate = useNavigate();
    const handleClose = () => {
        setOpenDialog(false);
        // Navigate to /post
        navigate('/post');
        // If you must reload after navigation:
        // window.location.reload();
        
        // (Optional) If you also want to notify a parent:
        if (onClose) {
          onClose();
        }
      };

      // Dynamically select the query and variables
  const { loading: loadingRewards, error: errorRewards, data: dataRewards } = useQuery(
    isCoffeeShop ? GET_DRINK_REDEEMS : GET_USER_REDEEMS1,
    {
      variables: isCoffeeShop ? { shop: user.id } : { userID: user.id },
    }
  );
  const totalDrinkRedeems = dataRewards?.redeems?.length || 0;
  const totalRewards = dataRewards?.redeems
  ? dataRewards.redeems.reduce((acc, curr) => acc + Number(curr.reward), 0)
  : 0;

// Format with commas
const formattedTotalRewards = totalRewards.toLocaleString('en-US');
 // 2) Original hardcoded fallback data
 

      const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
        fetchPolicy: 'cache-and-network',
        variables: { _id: user.id },
        skip: !user.id,
      });
    
      // set a constant for usertype coffeeShop is koffieshop
      const isKoffieShop = userData?.userprof.usertype === 'koffieshop';
    
    
      useEffect(() => {
        if (userData && userData.userprof) {
          setCashAppId(userData.userprof.cashappid || '');
          setZelleId(userData.userprof.zelleid || '');
          setPayout(userData.userprof.payout || '');
        }
      }, [userData]);
    
      const [updateUserProf] = useMutation(UPDATE_USER_PROF);
    
      const updatePayout = async (payout) => {
        try {
          await updateUserProf({
            variables: {
              query: { _id: user.id },
              set: { payout, cashappid: cashappid, zelleid: zelleid},
            },
          });
        } catch (error) {
          console.error(error);
        }
      };
    
      const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    fetchPolicy: 'cache-and-network',
    variables: { userID: user.id },
    skip: !user.id,
  });
 
  const { loading, error, data } = useQuery(GET_PAID_CART_BY_OWNER, {
    fetchPolicy: 'cache-and-network',
    variables: { prodowner: user.id },
    skip: !user.id,
  });
  
  useEffect(() => {
    if (cartData && cartData.carts) {
      const newItemsInBag = cartData.carts.map((item) => {
        return {
          name: item.name,
          price: item.price,
          imageurl: item.imageurl,
          farmer: item.farmer,
          _id: item._id,
          quantity: item.quantity,
          isPaid: item.isPaid,
          beanies: item.beanies,
          timeStamp: item.timeStamp,
          productId: item.productId,
         
        };
      });
      setItemsInBag1(newItemsInBag);
    }
  }, [cartData]);
  


  const total = itemsInBag1.reduce((acc, item) => acc + item.price, 0);
  const totalQuantity = itemsInBag1.reduce((acc, item) => acc + item.quantity, 0);
  const totalBeanies = itemsInBag1.reduce((acc, item) => acc + item.beanies, 0);
  const redeemdate = itemsInBag1.reduce((acc, item) => acc + item.timeStamp, 0);
  const totalSales = total / 100;            // Convert cents to dollars
  const netRevenue = (total * 0.9) / 100;    // Deduct 10% and convert cents to dollars
  
  const formattedTotalSales = totalSales.toLocaleString('en-US', { style: 'currency', currency: 'USD' }); // Format as currency
  const formattedNetRevenue = netRevenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' }); // Format as currency

  // example
const deals = itemsInBag1.length;     // or however you define “deals”
const tokensRedeemed = totalBeanies; // or some other calculation
  // Demo “stat cards” at the top
  // Replace these with your own values and logic
  
  
  const {loading: beanLoading, error: beanError, data: beanData, refetch} = useQuery(
    isCoffeeShop ? GET_USER_REDEEMS : GET_USERBEAN_REDEEMS ,
    {
      variables: isCoffeeShop ? { shopId: user.id } : { userId: user.id },
    }
  );
   
//get paid redeems
  const {loading:loadingPaid, error:errorPaid, data:dataPaid, refetch:refetchPaid} = useQuery(
    GET_PAID_REDEEMS,
    {
      variables: { shopId: user.id },
    }
  );

  const handleRefresh = async () => {
    try {
      await refetch(); // Manually trigger the refetch
    } catch (err) {
      console.error('Error during refetch:', err);
    }
  };
 

  const [insertOneCart] = useMutation(INSERT_CART_MUTATION);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  function timeAgo(date) {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;
  
    if (secondsPast < 60) {
      return `${Math.round(secondsPast)}s`;
    }
    if (secondsPast < 3600) {
      return `${Math.round(secondsPast / 60)}m`;
    }
    if (secondsPast <= 86400) {
      return `${Math.round(secondsPast / 3600)}h`;
    }
    if (secondsPast > 86400) {
      const day = date.getDate();
      const month = date.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
      const year = date.getFullYear() === now.getFullYear() ? "" : ` ${date.getFullYear()}`;
      return `${day} ${month}${year}`;
    }
  }

  const redeemItems = beanData?.shopredeems ?? [];

  // Sums up all 'payout' fields as integers
  // Sums up all 'payout' fields as integers
const totalPayout = beanData && beanData.shopredeems
? beanData.shopredeems.reduce((acc, curr) => {
    // Each payout is stored as an integer in cents (e.g., 20)
    const payoutInCents = Number(curr.payout || 0);
    return acc + payoutInCents;
  }, 0)
: 0;

console.log('Total Payout in cents:', totalPayout); 
// If you had 8 fields each with 20, this should log 160


const handleAddToBag = async () => {
  const newItem = {
    name: 'Koffieland Payout',
    price: totalPayout , // Convert to dollars
    shopID: user.id, // Use the shop ID here
    category: 'Payout',
    imageurl: RedeemImage,
   
    
  };

  // Update bag context
  setItemsInBag([...itemsInBag, newItem]);

  // Insert into cart via mutation
  try {
    await insertOneCart({
      variables: {
        data: {
          userID: user.id,
          price: totalPayout, // Convert to dollars
          category: 'Payout',
          name: newItem.name,
          isPaid: false,
          imageurl: RedeemImage,
          redeemIds: redeemItems.map((item) => item._id), // array of _id's
          quantity: 1,
        },
      },
    });
    console.log('Payout added to cart successfully');
    setSnackbarOpen(true);
  } catch (error) {
    console.error('Error adding payout to cart:', error);
  }
};
  // When user clicks a stat card, you can load specific data below, highlight the relevant chart, etc.
  // For demo, we just store which card was clicked
  //Total Items multiplied by 4000 to get total amout of beans reddemed from beanData. Each items is 4000 beans
    const digitokensRedeemed = beanData?.shopredeems?.length * 4000 || 0;
    const formattedNumber0 = digitokensRedeemed.toLocaleString('en-US');

    const totaloffset = dataPaid?.shopredeems?.length * 4000 || 0;
    const formattedNumber = totaloffset.toLocaleString('en-US');
    // Example values for the stat cards

    const [selectedCardId, setSelectedCardId] = useState(null);

  const handleCardClick = (cardId) => {
    setSelectedCardId(cardId);
  };
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  

  const totalGross1 = data.carts.reduce((acc, item) => acc + (item.price * item.quantity), 0) / 100; // Convert total to dollars
  const totalNet = (totalGross1 * 0.94).toLocaleString('en-US', { style: 'currency', currency: 'USD' }); // Format as currency
const formattedTotalGross1 = totalGross1.toLocaleString('en-US', { style: 'currency', currency: 'USD' }); // Format as currency
  const statCards = [
    {
      title: 'Total Spend',
      value: `Gross ${formattedTotalSales}`,  // e.g. "$30,794.00"
      value2: `Net ${formattedNetRevenue}`,   // e.g. "$27,714.60"
      id: 'sales'
    },
    {
      title: 'Digi Beans Issued',
      value: `Redeemed ${ formattedNumber}`,  // e.g. "$30,794.00"
      value2: `In Queue ${formattedNumber0}`,  // e.g. "$30,794.00"
      id: 'revenue'
    },
    {
      title: 'Total Sales',
      value: `Gross Sales $${totalGross1}`,
      value2: `Net Sales $${totalNet}`,
      id: 'deals'
    },
    {
      title: 'Rewards Redeemed',
      value: `Drinks ${totalDrinkRedeems}`,
      value2: `Beans ${formattedTotalRewards}`,
      id: 'tokens'
    }
  ];

  const fallbackData = [
    { name: `${redeemdate}`, deals: `${tokensRedeemed}`, revenue: 400 },
    { name: 'Jun', deals: 300, revenue: 450 },
    { name: 'Jul', deals: 250, revenue: 430 },
    { name: 'Aug', deals: 400, revenue: 480 },
    { name: 'Sep', deals: 350, revenue: 500 },
    { name: 'Oct', deals: 280, revenue: 420 }
  ];

  // 3) Handle loading / error states
  if (loading) return <Typography>Loading...</Typography>;
  if (error) {
    console.error('Query error:', error);
    return <Typography>Error loading data</Typography>;
  }

  // 4) If data is available, map it; otherwise use fallback
  //    Adjust the fields below to match your actual data schema
  const dealsRevenueData = Array.isArray(dataRewards) && dataRewards.length > 0
  ? dataRewards
      .filter(item => item && item.name && item.reward && item._id) // Filter out invalid entries
      .map(item => ({
        name: item.name,
        deals: item.reward,
        revenue: item._id
      }))
  : fallbackData;
    console.log('dealsRevenueData:', dealsRevenueData);

    // Pie chart data for “Top Revenue Channels”
   // Pie chart data for “Top Revenue Channels”
// Pie chart data for “Top Revenue Channels”
const channelData = [
  { name: 'In App Spend', value: parseFloat(totalSales) }, // Use totalGross1 for In App Spend
  { name: 'Total Sales', value: parseFloat(totalNet) },     // Use totalNet for Total Sales
  { name: 'Beans Issued', value: parseFloat(formattedNumber.replace(/,/g, '')) }, // Remove commas for parsing
  { name: 'Rewards Used', value: parseFloat(formattedTotalRewards.replace(/,/g, '')) } // Remove commas for parsing
];

const COLORS = ['#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

// Custom Tooltip Formatter
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box sx={{ backgroundColor: '#fff', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}>
        <Typography variant="body2">{`${payload[0].name}: ${payload[0].value.toLocaleString()}`}</Typography>
      </Box>
    );
  }
  return null;
};

// Custom Label Formatter
const renderCustomizedLabel = ({ name, value }) => {
  return `${name}: ${value.toLocaleString()}`;
};



  return (
    <Dialog
    fullScreen
    open={openDialog}
    onClose={handleClose}
    scroll="paper"
  >
      {/* ---------- AppBar / Toolbar at the Top (for close icon, etc) ---------- */}
      <AppBar sx={{ position: 'relative',   }}>
        <Toolbar>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
  <CloseIcon />
</IconButton>
          
          
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', padding: 1 }}>
                <Grid item xs={12} sm={6} md={6}>
           
                <Typography variant="h4" sx={{ fontWeight: 700,
                  fontSize: { xs: "1rem", sm: "1.8rem" },
                 }}>
                    Insights
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>


                <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000} // Snackbar will close after 6 seconds
  onClose={() => setSnackbarOpen(false)} // Close Snackbar when dismissed
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position of the Snackbar
>
  <Alert
    onClose={() => setSnackbarOpen(false)}
    severity="success" // Use "success" for a green success message
    sx={{ width: '100%' }}
  >
    Payout added, go to cart to complete transaction
  </Alert>
</Snackbar>

                {beanData && beanData.shopredeems && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          
         {isCoffeeShop && (
          <Box
          sx={{
            display: 'flex',
            width: '100%', // Full width
            alignItems: 'center', // Align items vertically
            justifyContent: 'space-between', // Evenly space out items horizontally
            
            
        
          }}
        >
          {/* Total Payout */}
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: '14px', // Slightly larger for better readability
              
            }}
          >
            Total Payout: ${(totalPayout / 100).toFixed(2)}
          </Typography>
        
          {/* Refresh Button */}
         
            <RefreshOutlined
            onClick={handleRefresh}
            sx={{
              width: '20px',
              height: '20px',
             
              '&:hover': { backgroundColor: '#b2ebf2' }, // Slightly darker hover effect
              color: '#00796b', // Matching the primary color scheme
              mb: 2, // Space between items
            }} />
         
        
          {/* Add to Bag Button */}
          <Button
            onClick={handleAddToBag}
            variant="contained"
            sx={{
              borderRadius: '20px', // Rounded for sleek look
              backgroundColor: '#077336', // Primary green
              textTransform: 'none', // Removes all caps
              fontSize: '14px', // Slightly larger for readability
              color: 'white', // White text for contrast
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
              padding: '8px 16px', // Proper padding for better clickability
              marginLeft: '8px', // Space between items
              '&:hover': { backgroundColor: '#065828' }, // Darker green on hover
              GET_USER_REDEEMS      }}
          >
            Add Payout to Bag
          </Button>
        </Box>
        )}



{!isCoffeeShop && (
          <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center', // Align items vertically
            justifyContent: 'space-between', // Evenly space out items horizontally
            
            borderRadius: '8px', // Rounded corners for a sleek look
           
          
        
          }}
        >
          {/* Total Payout */}
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: '14px', // Slightly larger for better readability
              color: '#333', // Darker text color for better contrast
            }}
          >
            Total Payout: ${(totalPayout / 100).toFixed(2)}
          </Typography>
        
          {/* Refresh Button */}
          <IconButton
            onClick={handleRefresh}
            sx={{
              backgroundColor: '#e0f7fa', // Subtle blue background for visual cue
              '&:hover': { backgroundColor: '#b2ebf2' }, // Slightly darker hover effect
              color: '#00796b', // Matching the primary color scheme
              marginLeft: '8px', // Space between items
            }}
          >
            <RefreshOutlined />
          </IconButton>
        
          {/* Add to Bag Button */}
          
        </Box>
        )}
   

        </Box>
      )}
                </Grid>
                </Grid>
            
        </Toolbar>
      </AppBar>
            {/*---------- Bar that says insight and button to right in a responsive grid  ---------- */}
           
            

      {/* ---------- Dashboard Content ---------- */}
      <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
  {/* LEFT COLUMN: Stat Cards */}
  <Grid item xs={12} md={4}>
  
    <Grid container spacing={2}>
   
      {statCards.map((card) => (
        <Grid key={card.id} item xs={6} md={6}>
           
          <Card elevation={1} sx={{ borderRadius: 2, padding:0 }}>
            <CardActionArea onClick={() => handleCardClick(card.id)}>
              <CardContent sx={{ padding: 1 }}>
                <Typography variant="body1" color="textSecondary">
                  {card.title}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {card.value}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {card.value2}
                </Typography>
              </CardContent>
              <CardActionArea sx={{ display: 'flex', justifyContent: 'flex-end', padding: 1 }}>
  <Typography variant="body2" sx={{ fontStyle: 'italic', padding: 0 }}>
    View Report
  </Typography>
</CardActionArea>
            </CardActionArea>
          </Card>
          
        </Grid>
      ))}
    
    </Grid>
    
  </Grid>

  {/* RIGHT COLUMN: Deals & Revenue Chart */}
  <Grid item xs={12} md={8}>
    <Box
      sx={{
        mt: 0,
        border: '1px solid #e0e0e0',
        borderRadius: 2,
        p: 2
      }}
    >
      
        <BeanTrackerReports />
      
      
    </Box>
  </Grid>
</Grid>

<Grid container spacing={2}>
  {/* LEFT COLUMN (Campaign Performance Table) */}
 

  {selectedCardId === 'sales' && (
  <Grid item xs={12} md={8}>
    <Box
      sx={{
        mt: 2,
        border: '1px solid #e0e0e0',
        borderRadius: 2,
        p: 2,
      }}
    >
      
      <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
        Your Koffie Lands Purchases
      </Typography>

      {/* If no items, show a fallback */}
      {itemsInBag1.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No items yet
        </Typography>
      ) : (
        <>
        <TableContainer sx={{ maxHeight: 440, overflowY: 'auto' }}>
          <Table size={isSmDown ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Cost</TableCell>
                
                
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsInBag1.map((item) => (
                <TableRow
                  key={item._id}
                  hover
                  onClick={() => handleDrawerOpen(item)} // optional
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>${(item.price / 100).toFixed(2)}</TableCell>
<TableCell>{item.quantity}</TableCell>
<TableCell>${((item.price / 100) * item.quantity).toFixed(2)}</TableCell>
                    
                    
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>

          {/* Totals */}
        
        </>
      )}
    </Box>
  </Grid>
)}



<Grid item xs={12} md={8}>
    <Box
      sx={{
        mt: 2,
        border: '1px solid #e0e0e0',
        borderRadius: 2,
        p: 2
      }}
    >
     {selectedCardId === 'revenue' && (
  <Grid item xs={12}>
    <Box
      sx={{
        mt: 2,
        border: '1px solid #e0e0e0',
        borderRadius: 2,
        p: 2,
      }}
    >
      {/* Title */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 0 }}>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', padding: 1 }}>
                <Grid item xs={12} sm={6} md={6}>
           
                <Typography variant="h4" sx={{ fontWeight: 700,
                  fontSize: { xs: "1rem", sm: "1.8rem" },
                 }}>
                    Tokens Reprot
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>


                <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000} // Snackbar will close after 6 seconds
  onClose={() => setSnackbarOpen(false)} // Close Snackbar when dismissed
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position of the Snackbar
>
  <Alert
    onClose={() => setSnackbarOpen(false)}
    severity="success" // Use "success" for a green success message
    sx={{ width: '100%' }}
  >
    Payout added, go to cart to complete transaction
  </Alert>
</Snackbar>

                {beanData && beanData.shopredeems && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          
         {isCoffeeShop && (
          <Box
          sx={{
            display: 'flex',
            width: '100%', // Full width
            alignItems: 'center', // Align items vertically
            justifyContent: 'space-between', // Evenly space out items horizontally
           
            
        
          }}
        >
          {/* Total Payout */}
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: '14px', // Slightly larger for better readability
              
            }}
          >
            Total Payout: ${(totalPayout / 100).toFixed(2)}
          </Typography>
        
          {/* Refresh Button */}
         
            <RefreshOutlined
            onClick={handleRefresh}
            sx={{
              width: '20px',
              height: '20px',
             
              '&:hover': { backgroundColor: '#b2ebf2' }, // Slightly darker hover effect
              color: '#00796b', // Matching the primary color scheme
              mb: 2, // Space between items
            }} />
         
        
          {/* Add to Bag Button */}
          <Button
            onClick={handleAddToBag}
            variant="contained"
            sx={{
              borderRadius: '20px', // Rounded for sleek look
              backgroundColor: '#077336', // Primary green
              textTransform: 'none', // Removes all caps
              fontSize: '14px', // Slightly larger for readability
              color: 'white', // White text for contrast
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
              padding: '8px 16px', // Proper padding for better clickability
              marginLeft: '8px', // Space between items
              '&:hover': { backgroundColor: '#065828' }, // Darker green on hover
              GET_USER_REDEEMS      }}
          >
            Add Payout to Bag
          </Button>
        </Box>
        )}



{!isCoffeeShop && (
          <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center', // Align items vertically
            justifyContent: 'space-between', // Evenly space out items horizontally
            
            borderRadius: '8px', // Rounded corners for a sleek look
           
          
        
          }}
        >
          {/* Total Payout */}
          <Typography
            variant="caption"
            sx={{
              fontWeight: 500,
              fontSize: '14px', // Slightly larger for better readability
              color: '#333', // Darker text color for better contrast
            }}
          >
            Total Payout: ${(totalPayout / 100).toFixed(2)}
          </Typography>
        
          {/* Refresh Button */}
          <IconButton
            onClick={handleRefresh}
            sx={{
              backgroundColor: '#e0f7fa', // Subtle blue background for visual cue
              '&:hover': { backgroundColor: '#b2ebf2' }, // Slightly darker hover effect
              color: '#00796b', // Matching the primary color scheme
              marginLeft: '8px', // Space between items
            }}
          >
            <RefreshOutlined />
          </IconButton>
        
          {/* Add to Bag Button */}
          
        </Box>
        )}
   

        </Box>
      )}
                </Grid>
                </Grid>
            </Box>

      {/* MUI Tabs */}
      <Tabs value={selectedTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
        <Tab label="Shop Redeems" />
        <Tab label="User Bean Transactions" />
      </Tabs>

      {/* Tab Panels */}
      {selectedTab === 0 && (
        <TableContainer sx={{ maxHeight: 440, overflowY: 'auto' }}>
          <Table size={isSmDown ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
                <TableCell>Client</TableCell>
                <TableCell>Claimed</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Redeemed</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {beanData?.shopredeems?.map((redeem) => (
                <TableRow key={redeem._id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Avatar alt={redeem.name} src={redeem.uimage} />
                      {redeem.name}
                    </Box>
                  </TableCell>
                  <TableCell>{redeem.beanCount}</TableCell>
                  <TableCell>${(Number(redeem.payout) * 0.01).toFixed(2)}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {timeAgo(new Date(redeem.timestamp))}
                    </Box>
                  </TableCell>
                  <TableCell>{redeem.shopId}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedTab === 1 && (
        <TableContainer sx={{ maxHeight: 440, overflowY: 'auto' }}>
          <Table size={isSmDown ? 'small' : 'medium'}>
            <TableHead>
              <TableRow>
              <TableCell>Client</TableCell>
                <TableCell>Claimed</TableCell>
                <TableCell>Paid</TableCell>
                <TableCell>Redeemed</TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
             {dataPaid?.shopredeems?.map((redeem) => (
                 <TableRow key={redeem._id}>
                 <TableCell>
                   <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                     <Avatar alt={redeem.name} src={redeem.uimage} />
                     {redeem.name}
                   </Box>
                 </TableCell>
                 <TableCell>{redeem.beanCount}</TableCell>
                 <TableCell>${(Number(redeem.payout) * 0.01).toFixed(2)}</TableCell>
                 <TableCell>
                   <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                     {timeAgo(new Date(redeem.timestamp))}
                   </Box>
                 </TableCell>
                 <TableCell>{redeem.shopId}</TableCell>
               </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  </Grid>
)}


{selectedCardId === 'deals' && (
  <Grid item xs={12}>
    {/* Another table or detail for deals */}
    <Box sx={{ padding: 1 }}>
      {/* 🏷 Revenue Heading */}
      <Typography variant="body1" sx={{ fontWeight: 700, marginBottom: 2 }}>
        Paid Orders from Your Products
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 600, color: 'green' }}>
        Total Gross: ${totalGross1.toFixed(2)} | Net Revenue (after 6% fee): ${totalNet}
      </Typography>

      {/* 📜 Scrollable Box */}
      <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', gap: 2, padding: 2 }}>
        {data.carts.map((item) => (
          <Card key={item._id} sx={{ minWidth: 150, maxWidth: 280, display: 'inline-block' }}>
            <CardMedia
              component="img"
              height="160"
              image={item.imageurl}
              alt={item.name}
              sx={{ objectFit: 'cover' }}
            />
            <CardContent>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {item.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Price: ${(item.price / 100).toFixed(2)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Quantity: {item.quantity}
              </Typography>
            </CardContent>
            <CardActionArea sx={{ padding: 1 }}>
            <Chip
  label="View Details"
  onClick={() => handleDrawerOpen(item)}
  sx={{
    backgroundColor: '#077336', // Custom green color
    color: 'white',             // Ensures text is readable
    textTransform: 'none',      // Removes ALL CAPS
    fontSize: '14px',           // Adjusts text size
    padding: '8px 12px',        // Adds comfortable spacing
    '&:hover': { backgroundColor: '#065a2a' } // Darker shade on hover
  }}
/>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  </Grid>
)}

{selectedCardId === 'tokens' && (
  <Grid item xs={12}>
    {/* Another table or detail for tokens redeemed */}
    <Box sx={{ padding: 2 }}>
      {loading && <CircularProgress />}
      {error && <Typography color="error">Error: {error.message}</Typography>}

      {dataRewards && dataRewards.redeems && (
        <Box>
          {/* Header Section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Total Redeemed Beans: {formattedTotalRewards}
            </Typography>
            {isCoffeeShop && (
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Koffieland Payout: ${(dataRewards.redeems.length * 0.2).toFixed(2)}
              </Typography>
            )}
          </Box>

          {/* Grid Layout for Redeems */}
          {/* Grid Layout for Redeems */}
          <Grid container spacing={2}>
            {dataRewards.redeems.map((redeem) => (
              <Grid item xs={4} sm={6} md={4} key={redeem._id}>
                <Card sx={{ boxShadow: 3, borderRadius: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                  {/* Reward Image */}
                  <CardMedia
                    component="img"
                    height="100"
                    image={redeem.imageurl || 'https://via.placeholder.com/300'} // Fallback image
                    alt={redeem.Title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}>
                      {/* User Avatar */}
                      <Avatar src={redeem.uimage} alt={redeem.name} />
                      {/* User Name */}
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {redeem.name}
                      </Typography>
                    </Box>
                    {/* Reward Title */}
                    <Typography variant="body2" color="text.secondary" sx={{  marginBottom: 1 }}>
                      {redeem.Title}
                    </Typography>
                    {/* Bean Count */}
                    <Typography variant="body2" sx={{ }}>
                      Beans: {redeem.reward}
                    </Typography>
                    {/* Redeem Date */}
                    <Typography variant="caption" color="text.secondary">
                      {formatDate(redeem.redeemedAt)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {dataRewards && dataRewards.redeems.length === 0 && (
        <Typography>No redeems found for your account.</Typography>
      )}

      {!dataRewards && !loading && (
        <Typography>
          You have not redeemed DigiBeans yet. Spread the word by letting your customers know.
        </Typography>
      )}
    </Box>
  </Grid>
)}
      <TableContainer sx={{ maxHeight: 440, overflowY: 'auto' }}>
      <LikesTable />
      </TableContainer>
    </Box>
  </Grid>
  {/* RIGHT COLUMN (Pie Chart) */}
  <Grid item xs={12} md={4}>
  <Box
    sx={{
      mt: 2,
      border: '1px solid #e0e0e0',
      borderRadius: 2,
      p: 0,
      height: 400 // or any fixed height you prefer
    }}
  >
  <Typography variant="subtitle1" sx={{ mb: 2, ml: 1, mt: 1, fontWeight: 'bold' }}>
  Top Revenue Channels
</Typography>
<ResponsiveContainer width="100%" height="80%">
  <PieChart>
    <Pie
      data={channelData}
      dataKey="value"
      nameKey="name"
      label
      outerRadius={100}
      fill="#8884d8"
    >
      {channelData.map((entry, index) => (
        <Cell
          key={`cell-${index}`}
          fill={COLORS[index % COLORS.length]}
        />
      ))}
    </Pie>
    <Tooltip />
    <Legend />
  </PieChart>
</ResponsiveContainer>
  </Box>
</Grid>
</Grid>

<Drawer anchor="right" open={open} onClose={handleDrawerClose}
    sx={{  width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1500 }}
    >
      <IconButton onClick={handleDrawerClose} sx={{ position: 'absolute', top: 0, left: 0 }}>
        <Close />
      </IconButton>
      {selectedItem && (
        <Box sx={{ width: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 5, mb: 2 , padding: 2}}>

          <Typography variant="h4" fontWeight={700}>{selectedItem.name}</Typography>
          <img src={selectedItem.imageurl} alt={selectedItem.name} style={{ width: '100%', marginTop: 16 , borderRadius: 5}} />
            <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="h6" color="textSecondary" sx={{ fontWeight: 500 }}>Beanies: {selectedItem.beanies}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary">Gross: </Typography>
          <Typography variant="body2" color="textSecondary">${selectedItem.price.toFixed(2)}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary"> 6% of Net Margin: </Typography>
          <Typography variant="body2" color="textSecondary">${(selectedItem.price * 0.06).toFixed(2)}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary">Net Margin: </Typography>
          <Typography variant="body2" color="textSecondary">${(selectedItem.price * 0.94).toFixed(2)}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary">Product ID: </Typography>
          <Typography variant="body2" color="textSecondary">{selectedItem.productId}</Typography>
          </Box>
          <Box
  sx={{
    display: "flex",
    justifyContent: "space-between", // Space between left and right content
    alignItems: "center",            // Vertically center content
    width: "100%",                   // Ensure it spans full width
    paddingX: 0,                     // Optional padding for better spacing
  }}
>
          <Typography variant="body2" color="textSecondary">Timestamp:</Typography>
          <Typography variant="body2" color="textSecondary">{selectedItem.timeStamp}</Typography>
          </Box>
        </Box>
      )}
    </Drawer>

        {/* ---------- Bottom Section: Campaign Performance Table ---------- */}
        
       
        {/* ---------- Bottom Section: Campaign Performance Table ---------- */}
        {/* Add more content here as needed */}
        {/* Example content to show scrolling */}
        {/* ---------- Example: You could conditionally display data based on selectedCardId ---------- */}
        {selectedCardId && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" color="textSecondary">
              You clicked “{selectedCardId}” above. This is where you can load specific
              data or highlight an area of the chart, etc.
            </Typography>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
