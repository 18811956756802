import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Drawer, IconButton, Card, Typography, Grid, Button, Divider, CardContent, CardActionArea,Container, CardMedia, Modal, Dialog, Fab } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import KComments from '../mods/kstorycomments';
import { gql, useQuery, useMutation ,useLazyQuery} from '@apollo/client';
import { Link } from 'react-router-dom';
import { usePostData } from '../tests/comquery';
import { makeStyles } from '@mui/styles';
import  Heart  from '../mods/displays/heart';
import useInfiniteScroll from '../../queries/listarray';
import Product from '../../components/finance/Product';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Box } from '@mui/material';

import PostAndUploadComponent from '../posts/post';
import CallToActionAd from '../finance/getkoffie';
import { useSpring, animated } from 'react-spring';
import Progress from '../mods/progress';
import FarmerAd from '../finance/adds/farmeradd';
import BaristaAd from '../finance/adds/baristaadd';
import RoasterAd from '../finance/adds/roasteradd';
import BeansAd from '../finance/adds/beansadd';
import ShopAd from '../finance/adds/shopadd';
import FarmerCard from '../nav/farmslider';
import StoreAdd from '../finance/adds/storeadd';
import  {UserContext}  from '../../context/usercontext';
import PComments from '../mods/postcomments';
import AiGen from '../finance/adds/aifeeds';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import { Close, ShowChart } from '@mui/icons-material';
import AiGen2 from '../finance/adds/aifeed2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StackableFoot from '../mods/displays/stackfoot';
import BurstEffect from '../mods/burst';
import MediaItem from './mediaitem';
import DailyGrind from '../../pages/DailyGrind';
import Landpopup from '../mods/snacks/landpopup';
import kcoin from '../../images/kcoin.png';
import { GET_USER } from '../../context/gqllogics';
import DailyGrindDesk from '../../pages/DailyGrindDesk';
import TopBean from '../mods/topbean';
import MarketplaceAdd from '../finance/nftmarketadd';
import BeanTicker from '../finance/beanwatchticker';



const BeanIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/newton.png' alt="Bean Icon" style={{width: 40, height: 40, backgroundColor: 'transparent' }} />
  )
}


const useStyles = makeStyles((theme) => ({
  root: {
     // You can set this to match the width in your UI
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(2),
    
  },
  media: {
    
    marginLeft: theme.spacing(5),
    
    borderRadius: 15,
    
    
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    marginLeft: theme.spacing(0),
  },
  actionArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(2),
    borderRadius: 10,
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.16)',
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 30px 0 rgba(0,0,0,0.16)',
    },
  },

  stickyArticle: {
    position: 'sticky',
    top: theme.spacing(0),
    alignSelf: 'flex-start',  
  },
  commentsContainer: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(10),
  },
  introBanner: {
    backgroundColor: '#f5f0e5', 
    padding: theme.spacing(2),
    textAlign: 'center',
    borderBottom: '0px solid #ddd',
    borderRadius: 10,
  },
  introHeading: {
    fontFamily: 'Times New Roman, serif',
    fontSize: '24px',
    marginBottom: theme.spacing(1),
  },
  introCallToAction: {
    fontStyle: 'italic',
  },
}));




export const GET_ALL_POSTS = gql`
  query GetAllPosts {
    posts(sortBy: CREATEDAT_DESC) {
      _id
      content
      createdAt
      author
      imageurl
      userimage
      contentType
      userID
    }
  }
`;



const GET_ALL = gql `
 query GetAllComments {
  comments {
    _id
    content
    contentId
    contentType
    author
    
  }
}
`;

const GET_ALL_LIKES = gql`
  query GetLikes($contentId: ObjectId!) {
    likes(query: { contentId: $contentId }, sortBy: AVATAR_ASC) {
      _id
      contentId
      contentType
      user
    }
  }
`;


function fetchMoreListItems() {
  // Simulate an API call
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
}








const DailyPost = ({ userprof }) => {
  const { user,  } = useContext(UserContext);
  const { Nickname, beanCount, imageurl } = user.customData || {}; 
  //console.log('beanCount:', beanCount);
  const classes = useStyles();
  const beanCountValue = beanCount && parseInt(beanCount.$numberInt, 10);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [fetchPosts, { loading, error, data }] = useLazyQuery(GET_ALL_POSTS, {
    fetchPolicy: 'network-only', // Ensures fresh data from the server
  });
  const renderMedia = useCallback(
    (media, title) => {
      if (media && media.length > 0) {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              marginTop: 2,
              padding: 0,
            }}
          >
            {media.map((item, index) => (
              <MediaItem key={index} item={item} title={title} />
            ))}
          </Box>
        );
      }
  
      return null; // Return null if there's no media
    },
    [] // Add dependencies here if the logic depends on any props or state
  );

  useEffect(() => {
    // Trigger fetchPosts when the component loads
    fetchPosts();
    loading && <Progress/>
  }, [fetchPosts]);
  
  const [fetchUser, { data: userData, loading: userLoading, error: userError }] = useLazyQuery(GET_USER);
  const [userProfiles, setUserProfiles] = useState({});
  useEffect(() => {
    if (data?.posts) {
      data.posts.forEach((post) => {
        if (!userProfiles[post.userID]) {
          // Fetch the user's profile by their userID if not already fetched
          fetchUser({
            variables: { _id: post.userID },
            onCompleted: (fetchedData) => {
              setUserProfiles((prevProfiles) => ({
                ...prevProfiles,
                [post.userID]: fetchedData.userprof,
              }));
            },
          });
        }
      });
    }
  }, [data, fetchUser, userProfiles]);
  
  const [postSubmitted, setPostSubmitted] = useState(false);
  const [items, setItems] = useState(data ? data.posts.slice(0, 50) : []);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);
 
    
    const { data: allCommentsData } = useQuery(GET_ALL);
  const { data: allLikesData } = useQuery(GET_ALL_LIKES);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedCards, setExpandedCards] = React.useState({});
  
  const [isLoading, setIsLoading] = useState(true);

  // Simulate loading time for the DailyGrind component (for demonstration)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Simulate that the content is loaded after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);
 
  const actions = [
    { icon: <BeanIcon sx={{ fontSize: 100 }} />, name: 'Bean'},
   

  ];

  const handleSpeedDialActionClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the popover
  };

  const handlePopoverClose = () => {
    setAnchorEl(null); // Close the popover
  };

  const handleExpandClick = (id) => {
    setExpandedCards((prev) => ({ ...prev, [id]: !prev[id] }));
  };

//show more
const [showMore, setShowMore] = useState(false);

const handleShowMore = () => {
  setShowMore(!showMore);
};

const ads = [
  { id: "MarketplaceAdd", component: <MarketplaceAdd /> },
  { id: "TopBean", component: <TopBean /> },
  { id: "AiGen2", component: <AiGen2 /> },
  { id: "StoreAdd", component: <StoreAdd /> },
];
  
  const [props, set] = useSpring(() => ({
    transform: 'scale(1)',
  }));

  const handleBounce = () => {
    set({ transform: 'scale(1.2)' });
    setTimeout(() => {
      set({ transform: 'scale(1)' });
    }, 400); // Reset to original scale after 2 seconds
  };

  const [open, setOpen] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState('');

    const handleOpen = (imageUrl) => {
        setCurrentImageUrl(imageUrl); // Set the current image URL
        setOpen(true);
    };

   

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Function to handle opening modal and setting image or video URL
const handleOpenModal = (mediaUrl) => {
  if (!mediaUrl) {
    console.warn("Media URL is missing or invalid."); // Log warning if mediaUrl is missing
    return;
  }
  setCurrentImageUrl(mediaUrl); // Set the current media URL
  setOpen(true); // Open the modal
};

// Function to close modal
const handleClose = () => {
  setOpen(false); // Close the modal
  setCurrentImageUrl(null); // Reset the media URL to null for better clarity
};

  useEffect(() => {
    if (postSubmitted) {
      setDrawerOpen(false);
      setExpanded(false);
      
      setPostSubmitted(false); // Reset for next time
    }
  }, [postSubmitted]);

  useEffect(() => {
    if (data && data.posts.length) {
      setItems(data.posts.slice(0, 200));
    }
  }, [data]);

  function timeAgo(date) {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;
  
    if (secondsPast < 60) {
      return `${Math.round(secondsPast)}s`;
    }
    if (secondsPast < 3600) {
      return `${Math.round(secondsPast / 60)}m`;
    }
    if (secondsPast <= 86400) {
      return `${Math.round(secondsPast / 3600)}h`;
    }
    if (secondsPast > 86400) {
      const day = date.getDate();
      const month = date.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
      const year = date.getFullYear() === now.getFullYear() ? "" : ` ${date.getFullYear()}`;
      return `${day} ${month}${year}`;
    }
  }
  
 

  function fetchMoreListItems() {
    if (!data || items.length >= data.posts.length) return;
    setIsFetching(true);
    setTimeout(() => {
      const moreItems = data.posts.slice(items.length, items.length + 100);

      setItems((prevState) => ([...prevState, ...moreItems]));
      setIsFetching(false);
    }, 1500);
  }

  const [hearts, setHearts] = useState([]);
 
  useEffect(() => {
    const interval = setInterval(() => {
      const newHeart = {
        id: Math.random(),
        left: Math.random() * window.innerWidth,
        top: window.innerHeight,
      };
      setHearts((prevHearts) => [...prevHearts, newHeart]);

      setTimeout(() => {
        setHearts((prevHearts) =>
          prevHearts.filter((heart) => heart.id !== newHeart.id)
        );
      }, 5000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const beanUrl = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';


  // If BeanCont is more tahn 200 show kcoin else null
  

  if (loading) return <p><Progress/></p>;
    if (error) return <p>Error :</p>;
    
    
    

// Retrieve the user profile by ID
//const currentUserprof = userprofs ? userprofs.find((userprof) => userprof._id === currentUserprofId) : null;


// ...


  return (
    <Box sx={{ flexGrow: 1, paddingBottom: 5 }}>
      <BeanTicker />
      <Box sx={{ mt: 1 }}>
        
     
      {!isMobile ? ( <DailyGrindDesk /> ) : ( <DailyGrind /> )}
      
    </Box>
    <Landpopup/>
        {!isMobile && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
      <div style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 1000, mb: 1 }}>
       <StackableFoot/>
      </div>
    </Box>
      }
      <Box sx={{ mt: 2}} >
     
        {!isMobile &&<Box sx={{ display: 'grow', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
      <PostAndUploadComponent setPostSubmitted={setPostSubmitted}/>
    </Box>
      }
      </Box>   
    <div>
    
      {/* Your existing page content */}
      {isMobile && 
      <Box sx={{ position: 'fixed', bottom: '50px', right: '10px', zIndex: 1000}}>
      <animated.div style={props} 
      
        onClick={() => {
          setDrawerOpen(true);
        }}
        
      >
        
      </animated.div>
      <Fab
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 16, right: 16, }}
       
        onClick={
          setDrawerOpen}
      >
        <AddIcon />
      </Fab>
       
      </Box>
      }
      <Dialog className="fade-in" anchor="top" open={drawerOpen} onClose={handleDrawerToggle} fullScreen  >
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
  <Avatar alt="user" src={imageurl} />
    <Typography variant="body1" component="div" sx={{ flexGrow: 1, paddingLeft: 1, fontWeight: 500 }}>
      Add a Post
    </Typography>
    <IconButton onClick={handleDrawerToggle}>
      <Close />
    </IconButton>
  </Box>
  <Divider />
  
   <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      <PostAndUploadComponent setPostSubmitted={setPostSubmitted}/>
    </Typography>
  </Box>

</Dialog>

    </div>
   
 
    <Grid  spacing={1} justifyContent="center">
      <>
      </>
       {items.map((post, index) =>  {
        const commentsCounts = allCommentsData?.comments.filter(comment => comment.contentId === post._id);
        const likesCounts  = allLikesData?.likes.filter(like => like.contentId === post._id);

        return (
          <Box key={`post-${post._id || index}`} mb={2}>
           
           <Timeline sx={{ [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 }, borderBottom: 'solid 0px #d1d1d1' }}>
  <TimelineItem>
  <Link to={`/posts/${post._id}`} key={post._id} style={{ textDecoration: 'none', color: 'inherit' }}>
    <TimelineSeparator>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
     
        {post.userimage && (
          <Avatar alt="Eat" src={post.userimage} /> 
        )}
      
      </Box>
    </TimelineSeparator>
    </Link>
    <TimelineContent sx={{ padding: 0 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 0, gap: 0, justifyContent: 'space-between', paddingBottom: 0, paddingLeft: 1 }}>
      <Typography
  variant="body2"
  sx={{
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  }}
>
  {post.author}
  {beanCountValue > 200 && (
    <img
      src={kcoin}
      alt="Bean"
      style={{ width: 20, height: 20, marginLeft: 5, marginTop: 5 }}
    />
  )}
</Typography>

        <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 400 }}>
          {timeAgo(new Date(post.createdAt))} {/* Convert createdAt to time ago format */}
        </Typography>
      </Box>

      

      {/* Conditionally render media content if imageurl exists */}
      {/* Image rendering logic */}
      <>
      <Link to={`/posts/${post._id}`} key={post._id} style={{ textDecoration: 'none', color: 'inherit' }}>
  <Typography
    variant="body2"
    sx={{
      display: 'inline', // Change to inline for better control over line break
      color: 'inherit',
    }}
  >
    {post.content.length > 150 && !showMore
      ? (
        <>
          {post.content.slice(0, 150).trim()} {/* Limit to the first 100 characters */}
          <Box
            component="span"
            onClick={(e) => { e.preventDefault(); handleShowMore(); }}
            sx={{
              color: theme.palette.primary.main,
              cursor: 'pointer',
              display: 'inline',
              textDecoration: 'none',
              '&:hover': {
                color: theme.palette.primary.dark,
              },
              marginLeft: '4px', // Small space to separate from truncated content
            }}
          >
            Show more
          </Box>
        </>
      )
      : (
        <>
          {post.content.trim()}
          {post.content.length > 100 && (
            <Box
              component="span"
              onClick={(e) => { e.preventDefault(); handleShowMore(); }}
              sx={{
                color: theme.palette.primary.main,
                cursor: 'pointer',
                display: 'inline',
                textDecoration: 'none',
                '&:hover': {
                  color: theme.palette.primary.dark,
                },
                marginLeft: '4px',
              }}
            >
              Show less
            </Box>
          )}
        </>
      )
    }
  </Typography>
</Link>


  {post.imageurl && (
    <Box 
      onClick={() => handleOpenModal(post.imageurl)} 
      sx={{ display: 'flex', alignItems: 'center', mt: -2, gap: 2 }}
    >
      {renderMedia([post.imageurl], post.content, post.imageurl)}
    </Box>
  )}
  
  
  
  
</>

<Modal
  open={open}
  onClose={handleClose}
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  BackdropProps={{
      style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' },
  }}
>
  <Box style={{ outline: 'none', maxWidth: '90vw', maxHeight: '90vh', overflow: 'hidden' }}>
    {currentImageUrl && !currentImageUrl.endsWith('.mp4') && (
      <img 
        src={currentImageUrl} 
        alt="Media" 
        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} 
      />
    )}
  </Box>
</Modal>


      <Box sx={{ alignItems: 'center' }}>
        <PComments post={post} />
      </Box>
    </TimelineContent>
  </TimelineItem>
  <Divider />
</Timeline>
{/* mb = margin-bottom */}
          
<Box sx={{ flexGrow: 1 }} />

           
         <Card className={''}>
    
  <Drawer
  anchor="top"
  sx={{
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }}
  open={expandedCards[post._id]}
  onClose={() => handleClose(post._id)}
>
  <div style={{ flex: 1, overflow: 'auto' }}>
    <Card style={{ marginBottom: 16 }}>
      <Typography variant="h6" component="div">
        {post.year}
      </Typography>
      <CardMedia
        component="img"
        height="200"
        width={200}
        image={post.imageurl}
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {post.What}
        </Typography>
        
      </CardContent>
    </Card>
    <div style={{ flex: 1 }}>
      
      <PComments post={post} />
    </div>
  </div>
</Drawer>

  
</Card>
   

            {/* Show Product component every 8 posts */}
{index % 8 === 0 && (
  <div key={`ad-${index}`}>
    {ads[Math.floor(index / 8) % ads.length].component}
  </div>
)}
            
   
             
        </Box>
      );
            
      })}

      
     
     <>
      {isFetching && <>Loading...</>}
        </>
     
    </Grid>
    </Box>
  );
};

export default DailyPost;
