import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  InputAdornment,
} from '@mui/material';
import { ArrowBack, ConstructionOutlined, Search } from '@mui/icons-material';


// Mock data with price changes
const bigCoffee = [
  {
    name: 'Coffee',
    tickerSymbol: 'KC',
    price: '204.00',
    change: '+1.50',
    imageUrl: 'https://example.com/coffee_commodities.png',
  },
  {
    name: 'Crude Oil',
    tickerSymbol: 'CL',
    price: '84.81',
    change: '-0.75',
    imageUrl: 'https://example.com/starbucks.png',
  },
  {
    name: 'Platinum',
    tickerSymbol: 'KDP',
    price: '7.78',
    change: '+0.10',
    imageUrl: 'https://example.com/keurig_dr_pepper.png',
  },
  {
    name: 'Gold',
    tickerSymbol: 'GC',
    price: '2,311',
    change: '+15.00',
    imageUrl: 'https://example.com/nestle.png',
  },
  {
    name: 'Copper',
    tickerSymbol: 'HG',
    price: '4.23',
    change: '-0.05',
    imageUrl: '',
  },
  {
    name: 'Cocoa',
    tickerSymbol: 'CC',
    price: '9,132',
    change: '+50.00',
    imageUrl: 'https://example.com/nestle.png',
  },
  {
    name: 'Sugar',
    tickerSymbol: 'SB',
    price: '21.97',
    change: '-0.30',
    imageUrl: '',
  },
  {
    name: 'Corn',
    tickerSymbol: 'ZC',
    price: '447.00',
    change: '+2.00',
    imageUrl: '',
  },
  {
    name: 'Oats',
    tickerSymbol: 'QSR',
    price: '335.50',
    change: '-1.25',
    imageUrl: '',
  },
  {
    name: 'Orange Juice',
    tickerSymbol: 'OJ',
    price: '363.50',
    change: '+0.75',
    imageUrl: '',
  },
  {
    name: 'Live Cattle',
    tickerSymbol: 'LE',
    price: '175.95',
    change: '-0.50',
    imageUrl: '',
  },
];

function MarketWatch(props) {
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredCommodities = bigCoffee.filter((commodity) =>
    commodity.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', padding: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
        <IconButton onClick={() => window.history.back()}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h4" gutterBottom sx={{ flexGrow: 1, textAlign: 'center' }}>
          Market Watch
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        In Development <ConstructionOutlined />
      </Typography>
      <TextField
        fullWidth
        placeholder="Search commodities..."
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <TableContainer component={Paper} sx={{ width: '100%', marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{  }}>
              <TableCell>Commodity</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Change</TableCell>
              <TableCell align="right">Symbol</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCommodities.map((commodity, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:hover': { backgroundColor: '#fafafa' },
                  '&:nth-of-type(odd)': {  },
                }}
              >
                <TableCell component="th" scope="row" sx={{ display: 'flex', alignItems: 'center' }}>
                  {commodity.imageUrl && (
                    <img
                      src={commodity.imageUrl}
                      alt={commodity.name}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                  )}
                  {commodity.name}
                </TableCell>
                <TableCell align="right">{commodity.price}</TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: commodity.change.startsWith('+') ? 'green' : 'red',
                    fontWeight: 'bold',
                  }}
                >
                  {commodity.change}
                </TableCell>
                <TableCell align="right">{commodity.tickerSymbol}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    
    </Box>
  );
}

export default MarketWatch;