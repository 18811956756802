import React from 'react';
import cx from 'clsx';
import { Card, CardMedia, Avatar, Typography, Chip, Box, Button, Grid,Paper } from '@mui/material';
import congo from '../../../images/congobag.png';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import ethio from '../../../images/ethioflag.png';
import '../../../context/priceticker.css';
import { UserContext } from '../../../context/usercontext';
import { useContext } from 'react';
import { styled } from '@mui/material/styles';
import LikesTable from '../beanwatch';


const Item = styled(Paper)(({ theme }) => ({
boxShadow: 'none',
paddiing: 0,
color: theme.themeColor,
}));

const GET_PRODUCTS = gql`
    query Products {
  products {
    Title
    _id
    description1
    description2  
    imageurl
    name
    price
  }
}
`;

const bigCoffee = [
  {
    "name": "Coffee ",
    "tickerSymbol": "KC",
    "price": "204.00",
    "imageUrl": "https://example.com/coffee_commodities.png"
  },
  {
    "name": "Crude Oil ",
    "tickerSymbol": "CL",
    "price": "84.81",
    "imageUrl": "https://example.com/starbucks.png"
  },
  {
    "name": "Platinum",
    "tickerSymbol": "KDP",
    "price": "7.78",
    "imageUrl": "https://example.com/keurig_dr_pepper.png"
  },
  {
    "name": "Gold",
    "tickerSymbol": "GC",
    "price": "2,311",
    "imageUrl": "https://example.com/nestle.png"
  },
  {
    "name": "Copper",
    "tickerSymbol": "HG",
    "price": "4.23",
    "imageUrl": ""
  },
  {
    "name": "Cocoa",
    "tickerSymbol": "CC",
    "price": "9,132",
    "imageUrl": "https://example.com/nestle.png"
  },
  {
    "name": "Sugar",
    "tickerSymbol": "SB",
    "price": "21.97",
    "imageUrl": ""
  },
  {
    "name": "Corn",
    "tickerSymbol": "ZC",
    "price": "447.00",
    "imageUrl": ""
  },
  {
    "name": "Oats",
    "tickerSymbol": "QSR",
    "price": "335.50",
    "imageUrl": ""
  },
  {
    "name": "Orange Juice",
    "tickerSymbol": "OJ",
    "price": "363.50",
    "imageUrl": ""
  },
  {
    "name": "Live Cattle",
    "tickerSymbol": "LE",
    "price": "175.95",
    "imageUrl": ""
  }
];


export const PriceTicket = React.memo(function PlaneTicketCard2() {
  
  const { user } = useContext(UserContext);

  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    
  });
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  console.log(data);

  

  return (
    <div className="ticker-container2">
      <Link to="/market-watch">
    <Grid container spacing={2}> {/* Grid Container for Overall Structure */}
  
  <Grid item xs={12}> {/* Grid Item for List Items */}
    <div className="ticker-wrapper2"> {/* Wrap for Scrolling Effect (Optional) */}
      {bigCoffee.map((product, index) => (
        <div key={index} className="ticker-item">
          <Card sx={{ padding: 1, border: 'solid 1px', borderColor: '#d1d1d1' }}> {/* Card for List Item */}
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Item>
          <Typography variant="body2" fontWeight={500}>{product.tickerSymbol}</Typography>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
          <Typography variant="caption" fontWeight={500} sx={{ display: 'flex', justifyContent: 'flex-end' }}>{product.name}</Typography>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
          <Typography variant="caption" color='text.secondary' >
            ${product.price}
          </Typography>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
          <Typography variant="caption" color='green' sx={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 500 }}>
            ${product.price}
          </Typography>
          </Item>
        </Grid>
      </Grid>
          </Card>
        </div>
      ))}
      {/* Duplicate the items for a continuous effect */}
     
    </div>
  </Grid>
</Grid>

</Link>

    </div>
 
  
  
  );
});

export default PriceTicket;