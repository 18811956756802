import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Chip,
  Grid,
  Dialog,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@mui/material';
import '../../context/priceticker.css';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  padding: 0,
  color: theme.themeColor,
}));

// GraphQL Query
export const GET_LIKES = gql`
  query GetRewards($limit: Int, ) {
    likes(limit: $limit, sortBy: CREATEDAT_DESC ) {
      _id
      likecount
      avatar
      createdAt
      name
      to
    }
  }
`;

const ChipContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  margin: '0 auto',
}));

const ChipItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'left',
  margin: '8px',
  borderRadius: '10px',
  minWidth: '170px',
  maxWidth: '400px',
  [theme.breakpoints.down('md')]: {
    flex: '1 1 calc(33.3% - 16px)',
    maxWidth: 'none',
  },
}));

function BeanTicker() {
  const [openDialog, setDialog] = React.useState(false);
    const [openDialog1, setDialog1] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState(null); // Track selected group
  const { data: likesData, loading: loadingLikes, error: errorLikes } = useQuery(GET_LIKES, {
    variables: { limit: 100000 },
    fetchPolicy: 'network-only',
  });

  // Open dialog and set selected group
  const handleOpen = (likecount) => {
    setSelectedGroup(likecount);
    setDialog(true);
  };

  // Close dialog
  const handleClose = () => {
    setDialog(false);
    setSelectedGroup(null); // Reset selected group
  };

    const handleClose1 = () => {
    setDialog1(false);
   
    };
const handleOpen1 = () => {
    setDialog1(true);
    
    };

  // Group and sum likes
  const groupAndSumLikes = (likes) => {
    const grouped = {};

    likes.forEach((like) => {
      const { likecount, avatar } = like;

      if (!grouped[likecount]) {
        grouped[likecount] = {
          avatar, // Use the first avatar in the group
          count: 0,
          total: 0,
          items: [], // Store all items in the group
        };
      }

      grouped[likecount].count += 1; // Increment the count
      grouped[likecount].total += likecount; // Add to the total sum
      grouped[likecount].items.push(like); // Add item to the group
    });

    return grouped;
  };

  // Sum all likes
  const sumLikes = (likes) => {
    return likes.reduce((total, like) => total + like.likecount, 0);
  };

  // Grouped data
  const groupedData = Array.isArray(likesData?.likes) ? groupAndSumLikes(likesData.likes) : {};

  // Total likes
  const totalLikes = Array.isArray(likesData?.likes) ? sumLikes(likesData.likes) : 0;

  if (loadingLikes) return <CircularProgress />;
  if (errorLikes) return <Typography color="error">Error loading data</Typography>;

  return (
    <>
  
  <Box sx={{ width: '100%', margin: '0 ', justifyContent: 'flex-start', padding: 0 }}>
  <Typography 
 
    
    onClick={handleOpen1} 
    sx={{ 
      cursor: 'pointer', 
      color: '#077336', 
      fontSize: '.8rem',
      textAlign: 'right',   // Align text to the right
      fontStyle: 'italic' ,  // Make font italic
      mr: 1,
      mb:0
    }}>
    all tickers
  </Typography>
</Box>
      <div className="ticker-container2">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="ticker-wrapper2">
              <ChipContainer>
                {Object.entries(groupedData).map(([likecount, group]) => (
                  <ChipItem key={likecount} onClick={() => handleOpen(likecount)}>
                    <Chip
                      size="small"
                      icon={
                        <img
                          src={group.avatar}
                          alt="Avatar"
                          style={{ width: 24, height: 24, borderRadius: '50%' }}
                        />
                      }
                      label={`Users: ${group.count} | Total: ${group.total}`}
                    />
                  </ChipItem>
                ))}
              </ChipContainer>
            </div>
          </Grid>
        </Grid>
      </div>
     
      {/* Dialog for selected group */}
      <Drawer
  anchor="bottom"
  open={openDialog}
  onClose={handleClose}
  sx={{ zIndex: 9999, width: '100%' }}
>
  <Box sx={{ width: 400, padding: 2 }}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 2,
        width: '100%',
      }}
    >
      <Typography variant="h6">Bean Exchange - Group Details</Typography>
      <IconButton onClick={handleClose}>
        <Close />
      </IconButton>
    </Box>

    {selectedGroup && (
  <Box>
    <Typography variant="body1" gutterBottom>
      Group: <b>{selectedGroup}</b>
    </Typography>
    <TableContainer sx={{  overflowY: 'auto' }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Token</TableCell>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
          <TableCell>Beans</TableCell>
          <TableCell>Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {groupedData[selectedGroup].items.map((item, index) => (
          <TableRow key={index}>
            <TableCell>
              <Avatar src={item.avatar} alt="Avatar" />
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.to}</TableCell>
            <TableCell>{item.likecount}</TableCell>
            <TableCell>{new Date(item.createdAt).toLocaleDateString()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  </Box>
)}
  </Box>
</Drawer>
      <Dialog fullScreen open={openDialog1} onClose={handleClose1}
sx={{ zIndex: 999 }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0 }}>
    
    <Box 
  sx={{ 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "space-between", // Pushes content apart
    width: "100%", // Ensures full width
    marginBottom: 0, 
    gap: 2 
  }}
>
    
    <IconButton onClick={handleClose1}>
        <Close />
    </IconButton>
  <Typography variant="body1" gutterBottom sx={{ fontWeight: 700 }}>
    Bean Exchange
  </Typography>
 
</Box>
<Box sx={{ width: '100%',  margin: '0 auto',  }}>
<Typography 
    variant="body1" 
    gutterBottom 
    sx={{  }}
  >
    Total Transactions: <b>{totalLikes}</b>
  </Typography>
  </Box>
    
  <TableContainer sx={{  overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ padding:2 }}>
              <TableCell>Token</TableCell>
              <TableCell align="right">Cost</TableCell>
              <TableCell align="right">Exchanged</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedData).map(([likecount, group]) => (
              <TableRow
                key={likecount}
                onClick={() => handleOpen(likecount)}
                sx={{
                  '&:hover': { backgroundColor: '#6fc28b' },
                  '&:nth-of-type(odd)': {  },
                }}
              >
                <TableCell>
                  <img
                    src={group.avatar}
                    alt="Avatar"
                    style={{ width: 30, height: 30, borderRadius: '50%' }}
                  />
                </TableCell>
                <TableCell align="right">{likecount}</TableCell>
                <TableCell align="right" sx={{fontWeight: 800, }}>{group.count}</TableCell>
                <TableCell align="right" sx={{fontWeight: 800, color: '#077336'}}>{group.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    </Dialog>
    </>
  );
}

export default BeanTicker;