// constants.js
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import * as Realm from "realm-web";

const APP_ID = process.env.REACT_APP_APP_ID; // Should match the server's Realm App ID
const app = new Realm.App(APP_ID);

// Helper function to get the token (from localStorage, or use Realm if needed)
async function getValidAccessToken() {
  let token = localStorage.getItem('accessToken');
  if (!token && app.currentUser) {
    try {
      await app.currentUser.refreshCustomData();
      token = app.currentUser.accessToken;
    } catch (e) {
      console.error('Could not refresh custom data:', e);
      throw e;
    }
  }
  if (!token) {
    throw new Error('User is not logged in');
  }
  return token;
}

// Create an HTTP link to Realm's GraphQL endpoint
const httpLink = new HttpLink({
  uri: `https://realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`,
});

// Set up middleware that attaches the token to each request
const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getValidAccessToken();
  return {
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const link = authLink.concat(httpLink);

// Initialize ApolloClient
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export { client, app };
