import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dhome from './homedesk';
import Process from '../blockchain/process';
import KoffieAI from '../components/profile/workingai';
import DailyPost from '../components/posts/dailypost';
import FarmOn from '../components/profile/onboarding/farmon';
import Thething from '../components/mods/thething';
//import Store from '../components/mods/frontgrid';
import SwipeAnimation from '../components/mods/swipeani';
import { useSpring, animated } from 'react-spring';
import { useState, useEffect } from 'react';
import { useMutation, useQuery, gql, useLazyQuery } from '@apollo/client';
import { UserContext } from '../context/usercontext';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import MobileFarm from './Mobilefarm';
import KupsterDash from './kupsterdash';
import FarmDash from './farmdash';
import { Link } from 'react-router-dom';
import { Button, Drawer, ListItem, ListItemText, IconButton, Avatar, Card, CardContent, CardHeader, CardMedia, CardActions, Collapse, Fab, Grid, Paper, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Flavloader from '../components/mods/flavloader';
import beans from '../images/coffeebeans.png';
import Brew from '../components/profile/brew';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import kpot from '../images/drip.png';
import Progress from '../components/mods/progress';
import { Chip, Container } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import star from '../images/star.png';

import reward from '../images/reward.png';
import pastries from '../images/yummy.png';
import { CircleNotifications, Margin } from '@mui/icons-material';
import MarketUI from './funstuff';
import StoreAdd from '../components/finance/adds/storeadd';
import RedeemCoffee from '../components/finance/redeemcoffee';
import Kwheel from '../components/mods/wheel';

import { useMediaQuery } from '@mui/material';


const health = 'https://storage.googleapis.com/app_darkendimg/newton.png';
const beaniefy = 'https://storage.googleapis.com/app_darkendimg/newton.png';

const rewardCategories = [
  { title: 'Coffee Points', icon: '☕', description: 'Earn points for every purchase',   },
  { title: 'Special Offers', icon: '🎁', description: 'Exclusive discounts and promotions' },
  { title: 'Premium Membership', icon: '🌟', description: 'Unlock premium benefits' },
];




const healthBenefits = [
  { title: 'Cancer', description: 'Coffee may affect how cancer develops, ranging from the initiation of a cancer cell to its death. For example, coffee may stimulate the production of bile acids and speed digestion through the colon, which can lower the amount of carcinogens to which colon tissue is exposed. Various polyphenols in coffee have been shown to prevent cancer cell growth in animal studies. Coffee has also been associated with decreased estrogen levels, a hormone linked to several types of cancer. [5] Caffeine itself may interfere with the growth and spread of cancer cells. [6] Coffee also appears to lower inflammation, a risk factor for many cancers.' },
  { title: 'Type 2 Diabetes', description: 'Although ingestion of caffeine can increase blood sugar in the short-term, long-term studies have shown that habitual coffee drinkers have a lower risk of developing type 2 diabetes compared with non-drinkers. The polyphenols and minerals such as magnesium in coffee may improve the effectiveness of insulin and glucose metabolism in the body.' },
  { title: 'Heart Disease', description: 'Coffee consumption has been associated with a lower risk of heart disease and stroke. In a meta-analysis of 36 studies, moderate coffee consumption (3-5 cups a day) was associated with a 19% lower risk of heart disease and a 30% lower risk of stroke compared with no coffee consumption. [8] The risk of heart disease and stroke was lowest with 3-4 cups a day, and higher with more than 5 cups a day. The risk of heart disease and stroke was also lower with decaffeinated coffee, but the association was weaker. ' },
  { title: 'Depression', description: 'Naturally occurring polyphenols in both caffeinated and decaffeinated coffee can act as antioxidants to reduce damaging oxidative stress and inflammation of cells. It may have neurological benefits in some people and act as an antidepressant. [13] Caffeine may affect mental states such as increasing alertness and attention, reducing anxiety, and improving mood. [14] A moderate caffeine intake of less than 6 cups of coffee per day has been associated with a lower risk of depression and suicide. However in a few cases of sensitive individuals, higher amounts of caffeine may increase anxiety, restlessness, and insomnia. Suddenly stopping caffeine intake can cause headache, fatigue, anxiety, and low mood for a few days and may persist for up to a week. ' },
  { title: 'Neurodegenerative diseases', description: 'Parkinson’s disease is mainly caused by low dopamine levels. There is consistent evidence from epidemiologic studies that higher consumption of caffeine is associated with lower risk of developing PD. The caffeine in coffee has been found in animal and cell studies to protect cells in the brain that produce dopamine.' },
  { title: 'Gallstones', description: 'There are various proposed actions of caffeine or components in coffee that may prevent the formation of gallstones. The most common type of gallstone is made of cholesterol. Coffee may prevent cholesterol from forming into crystals in the gallbladder. It may stimulate contractions in the gallbladder and increase the flow of bile so that cholesterol does not collect.' },
  { title: 'Mortality', description: 'In a large cohort of more than 200,000 participants followed for up to 30 years, an association was found between drinking moderate amounts of coffee and lower risk of early death. Compared with non-drinkers, those who drank 3-5 cups of coffee daily were 15% less likely to die early from all causes, including cardiovascular disease, suicide, and Parkinson’s disease. Both caffeinated and decaffeinated coffee provided benefits. The authors suggested that bioactive compounds in coffee may be responsible for interfering with disease development by reducing inflammation and insulin resistance. ' },
  
];

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 350,
   boxShadow: 0,
    borderRadius: '20px',
    transition: '0.3s',
     
  },
  content: {
    padding: 5,
    spacing: 8,
  },
  brandCardHeader: {
    fontSize:100,
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  }
}));



const AntTabs = styled(Tabs)({
  
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});



const GET_USER = gql`   
    query Userprof ($_id: ObjectId!)  {
        userprof (query: {_id: $_id}) {
            flavorprofile
            Nickname
            tasteProfile
            description
            name
            purchaseLink
            Koffie_story
            usertype
            _id
            country 
            imageurl
            beanCount
        }
    }
`;



const GET_KOFFY_DETAILS = gql`
    query GetKoffyDetails($countryName: String!){

koffy (query: {countryName: $countryName}){
  flag
  countryName
   altitudeRange {
    low
    high
  }
  coffeeProduction {
    annualOutput
    globalRank
  }
  harvestSeason
  keyFacts
  imageurl 
  history
  climate
  social
  political
  varieties
  wet
  dry
  economics
  hybrid
  pounds
  varieties 
  flavorProfile
    _id

  }

}
`;

const UPDATE_USER_PROF = gql`
  mutation UpdateUserprof($query: UserprofQueryInput!, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      tasteProfile
      description
      name
      country
      
    }
  }
`;


export default function MainSt() {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const passedUser = location.state?.user; // Access user data passed from Login component
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const [koffyDrawerOpen, setKoffyDrawerOpen] = useState(false);
  const [brewDrawerOpen, setBrewDrawerOpen] = useState(false);
  const [healthDrawerOpen, setHealthDrawerOpen] = useState(false);
  const [favoritesDrawerOpen, setFavoritesDrawerOpen] = useState(false);
const [pastriesDrawerOpen, setPastriesDrawerOpen] = useState(false);
const [rewardsDrawerOpen, setRewardsDrawerOpen] = useState(false);
const [updateUserprof] = useMutation(UPDATE_USER_PROF);
const { data, error, refetch } = useQuery(GET_USER, {
    variables: { _id: user?.id },
});
const [getKoffyDetails, { loading, data: koffyData }] = useLazyQuery(GET_KOFFY_DETAILS);
const handleBeanButtonClick = () => {
  // Use the country from the userprof data to trigger the lazy query
  getKoffyDetails({ variables: { countryName: data.userprof.country } });
  setKoffyDrawerOpen(true);
};

// To clear the tasteProfile and description
const handleClearProfile = () => {
const query = { _id: user.id };
const set = { tasteProfile: "", description: "", name: "", country: "" };

updateUserprof({ variables: { query, set } })
  .then(response => {
    console.log('Profile cleared:', response.data);
  })
  .catch(error => {
    console.error('Could not clear profile:', error);
  });
};
const swipe = useSpring({
  from: { transform: 'translateX(0px)' },
  to: { transform: 'translateX(-50px)' },
  reverse: true,
  delay: 500,
  config: { tension: 500, friction: 10 },
  onRest: () => {
    // Can add something here if you want an action after animation completes
  },
});
const handleWindowResize = () => {
  setWindowWidth(window.innerWidth);
};
useEffect(() => {
  if (passedUser) {
    // Do something with the passedUser
  }
}, [passedUser]);

useEffect(() => {
  if (user?.id) {
    refetch();
  }
}, [user?.id, refetch]);

useEffect(() => {
  window.addEventListener('resize', handleWindowResize);

  return () => {
      window.removeEventListener('resize', handleWindowResize);
  };
}, []);

if(loading) return <p><Progress/></p>
if(error) return <p>Error: {error.message}</p>

let userTypeComponent;

if(data && data.userprof) {
  switch(data.userprof.usertype) {
      case 'prodman':
          userTypeComponent = windowWidth <= 768 ? <MobileFarm user={data.userprof} /> : <FarmDash user={data.userprof} />;
          break;
      case 'kupster':
          userTypeComponent = windowWidth <= 768 ? <KupsterDash user={data.userprof} /> : <KupsterDash user={data.userprof} />;
          break;
      default:
          userTypeComponent = null;
  }
}

 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const cardData = [
    { title: 'Card 1', description: 'This is card 1' },
    { title: 'Card 2', description: 'This is card 2' },
    { title: 'Card 3', description: 'This is card 3' },
    { title: 'Card 4', description: 'This is card 4'},
    { title: 'Card 5', description: 'This is card 5' },
    // ... Add as many cards as you need
  ];

  
  return (
    <Box sx={{ padding: 2 }}>
     
<Card sx={{ 
  
  borderRadius: 5, 
  display: 'flex', 
  flexDirection: 'column', 
  alignItems: 'center', 
  justifyContent: 'space-evenly', 
  boxShadow: 0,
  padding: 0,
 
  
}}>
  <CardContent sx={{   display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', padding: 1,  }}>
  <div style={{
    
    display: 'flex', alignItems: 'left', padding: '0px', }}>
    <animated.div style={swipe}>
      <Typography variant='h6' sx={{ fontWeight: 900, alignItems: 'left',  padding: 1 }}>
      {data?.userprof?.name}
      </Typography>
    </animated.div>  
  
  </div>
 
  {/* Check if tasteProfile and description exist */}
  {data?.userprof?.tasteProfile && data?.userprof?.description ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'space-evenly', padding: 2, }}>
    
      <Typography variant='body1'  sx={{fontWeight: 800,  }}>
        {data?.userprof?.tasteProfile}
      </Typography>
      <Typography variant='body2 ' sx={{ color: 'text.secondary', mt: 1, mb: 1}}>
        {data?.userprof?.description}
      </Typography> 
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 0}}>
  {koffyData?.koffy.flag ? (
    // If flag URL exists, display it in the Avatar
    <Avatar
      src={koffyData.koffy.flag}
      onClick={handleBeanButtonClick}
      alt="Flag"
      style={{ display: 'block', margin: '0 auto', width: 40, height: 40 }}
    />
  ) : (
    // If flag URL doesn't exist, display a placeholder or label
    // This could be another <Avatar> with a different image or a <Typography> component for text
    <Typography variant='body2' onClick={handleBeanButtonClick} style={{ cursor: 'pointer', color: 'green', fontWeight: 700}}>
      Visit koffie State
    </Typography>
    // Or, if you prefer to show a placeholder image in the Avatar:
    // <Avatar
    //   src="path/to/your/placeholder/image.jpg"
    //   onClick={handleBeanButtonClick}
    //   alt="Placeholder"
    //   style={{ display: 'block', margin: '0 auto', width: 40, height: 40 }}
    // />
  )}
</Box>
      <Chip label="Clear Profile" onClick={handleClearProfile} sx={{mt: 1}} />
    </Box>
  ) : (
    <>
    <Typography variant='body1' sx={{ mb: 1, mt: 1, fontWeight: 500}}>
       Select an option to get started
      </Typography>
      <Box
  sx={{
    display: 'flex',
    justifyContent: 'space-between',  // Space buttons evenly
    gap: 2,  // Optional: adds some space between the chips
    width: '100%',  // Optional: ensure the Box takes full width
  }}
>
  <Link to="/ollie">
    <Chip label="Koffie Newbie" />
  </Link>  
  {isMobile && (
    <Link to="/wheel">
      <Chip label="Koffie Geeks" />
    </Link>
  )}
</Box>

    </>
  )}
</CardContent>
</Card>

  
  <Divider sx={{ mt: 2, mb: 2 }} />
  <Typography variant='body2' sx={{ fontWeight: 600,  }}>
              Koffie kit
            </Typography>
          <Box sx={{ display: 'flex',   mt: 2, borderRadius: 0, padding: 2 ,  }}>
           
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 10, sm: 8, md: 12 }}>
          

    <div
        role="presentation"
        onClick={() => setKoffyDrawerOpen(false)}
        onKeyDown={() => setKoffyDrawerOpen(false)}
    >
       
        <Drawer fullScreen anchor="right" open={koffyDrawerOpen} onClose={() => setKoffyDrawerOpen(false)}></Drawer>
                 
            {loading && <p>Loading...</p>}
            {koffyData && koffyData?.koffy && (
                 <Card sx={{ padding: 1, height: '100%' }}>
                 <CardContent>
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                   <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                     State Information
                   </Typography>
                    <img src={koffyData && koffyData.koffy.flag} alt="Flag" style={{ display: 'block', margin: '1 auto', width: 60, borderRadius: 5 }} />
                   </Box>
                   <Typography variant="body1" sx={{ marginBottom: 2 }}>
                     Coffee beans are the foundation of every great cup of coffee, each offering unique flavors and aromas that come to life through brewing.
                   </Typography>
                   <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2 }}>
                     {koffyData?.koffy.countryName}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <b> Altitude Range:</b> {koffyData?.koffy.altitudeRange.low} - {koffyData?.koffy.altitudeRange.high} meters above sea level
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <b> Annual Output:</b> {koffyData?.koffy.coffeeProduction.annualOutput} metric tons per year
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b>  Global Rank: </b> {koffyData?.koffy.coffeeProduction.globalRank} in the world
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b>  Harvest Season:</b> {koffyData?.koffy.harvestSeason}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> Key Facts:</b> {koffyData?.koffy.keyFacts}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> History:</b> {koffyData?.koffy.history}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b>Climate:</b>  {koffyData?.koffy.climate}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> Social:</b> {koffyData?.koffy.social}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> Varieties: </b>{koffyData?.koffy.varieties}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> Wet:</b> {koffyData?.koffy.wet}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> Dry:</b> {koffyData?.koffy.dry}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> Economics:</b> {koffyData?.koffy.economics}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> Hybrid:</b>  {koffyData?.koffy.hybrid}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> Pounds:</b>  {koffyData?.koffy.pounds}
                   </Typography>
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b> Flavor Profile:</b>  {koffyData?.koffy.flavorProfile}
                   </Typography>
                   <CardMedia component="img" image={koffyData?.koffy.imageurl} alt="Koffie State" sx={{ width: '100%', height: 200, borderRadius: 5 }} />
                   <Typography variant="body2" sx={{ marginBottom: 1 }}>
                   <b>ID: {koffyData?.koffy._id}</b>
                   </Typography>
                 </CardContent>
               </Card>
            )}
        
        
        {/* Rest of your drawer content */}
    </div>


        <Grid item xs={2} sm={4} md={4} >
          <Card sx={{  borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', padding: 0 }}>
        <Box>
        <img src={kpot} alt='beans' onClick={() => setDrawerOpen(true)} style={{ display: 'block', margin: '0 auto', width: 40 }} />
    </Box>
    <Box>
    <Typography variant='caption' sx={{fontWeight: 300}}>Brew</Typography>
    </Box>
        
       
        </Card>
        </Grid>
        <Drawer
  anchor="right"
  open={drawerOpen}
  onClose={() => setDrawerOpen(false)}
  sx={{
    '& .MuiDrawer-paper': {
      width: '100%', // Set the drawer's width to 100%
      padding: 0, // Add padding for content
      overflow: 'auto', // Handle overflow gracefully
    },
  }}
>
  {/* Content for the Brew drawer */}
  <div
    role="presentation"
    onClick={(e) => {
      if (!e.target.classList.contains('brew-method')) {
        setDrawerOpen(false);
      }
    }}
    onKeyDown={() => setDrawerOpen(false)}
    style={{ width: '100%' }}
  >
    {/* Header Section */}
   
    {/* Content Section */}
    <Card sx={{ width: '100%' }}>
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(false)}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6" sx={{ marginLeft: 2, fontWeight: '700' }}>
        Koffie Brewing Methods
      </Typography>
    </div>

      <Brew />
    </Card>
  </div>
</Drawer>



<Grid item xs={2} sm={4} md={4}>
<Card sx={{  borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', padding: 0 }}>
        <Box>
        <img src={health} alt='beans' onClick={() => setHealthDrawerOpen(true)} style={{ display: 'block', margin: '0 auto', width: 40 }} />
    </Box>
    <Box>
    <Typography variant='caption' sx={{fontWeight: 300 }}>Health</Typography>
    </Box>
       
    </Card>
</Grid>
<Drawer
  sx={{
    display: 'flex',
    '& .MuiDrawer-paper': {
      width: '100%', // Adjust width as needed
      
      padding: 2,
     
      boxShadow: 3,
    },
  }}
  anchor="right"
  open={healthDrawerOpen}
  onClose={() => setHealthDrawerOpen(false)}
>
  {/* Header Section */}
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #ddd',
      paddingBottom: 2,
      marginBottom: 2,
    }}
  >
    <IconButton
      edge="start"
      color="inherit"
      onClick={() => setHealthDrawerOpen(false)}
      sx={{ }}
    >
      <ArrowBackIcon />
    </IconButton>
    <Typography variant="h5" fontWeight="bold" >
      Coffee & Health
    </Typography>
  </Box>

  {/* Avatar Section */}
 

  {/* Health Message */}
  <Card
    sx={{
      marginBottom: 4,
      borderRadius: 3,
      boxShadow: 2,
      padding: 2,
      backgroundColor: '#BFE7F9',
    }}
  >
     <Box
    sx={{
      textAlign: 'center',
      
    }}
  >
    <Avatar
      alt="RX"
      src={beaniefy}
      sx={{
        margin: 'auto',
        width: 100,
        height: 100,
        
      }}
    />
  </Box>
    <CardContent>
      <Typography
        variant="body2"
        sx={{
          color: '#555',
          fontWeight: 500,
          lineHeight: 1.6,
          textAlign: 'center',
        }}
      >
        Drinking 3 to 5 standard cups of coffee daily may reduce the risk of
        several chronic diseases. Note that adding unhealthy condiments to
        coffee can negate these health benefits. Opt for black to maintain
        coffee's health advantages.
      </Typography>
    </CardContent>
  </Card>

  {/* Health Benefits Grid */}
  <Grid container spacing={2}>
    {healthBenefits.map((benefit, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Card
          sx={{
            borderRadius: 3,
            boxShadow: 2,
            overflow: 'hidden',
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-content"
              id={`panel-header-${index}`}
            >
              <img src={beaniefy} alt="Beans" style={{ width: 40, marginRight: 2 }} />
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  
                }}
              >
                {benefit.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                sx={{
                  
                  lineHeight: 1.5,
                }}
              >
                {benefit.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Grid>
    ))}
  </Grid>
</Drawer>

<>{koffyData?.koffy.history}</>
<Grid item xs={2} sm={4} md={4}>
<Card sx={{  borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', padding: 0 }}>
        <Box>
        <img src={star} onClick={() => setFavoritesDrawerOpen(true)} alt='beans' style={{ display: 'block', margin: '0 auto', width: 40 }} />
    </Box>
    <Box>
    <Typography variant='caption'sx={{fontWeight: 300 }}>Favs</Typography>
    </Box>
      
    </Card>
</Grid>
<Drawer anchor="right" open={favoritesDrawerOpen} onClose={() => setFavoritesDrawerOpen(false)}>
    {/* Content for the Favorites drawer */}
    <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <IconButton edge="start" color="inherit" onClick={() => setFavoritesDrawerOpen(false)}>
            <ArrowBackIcon />
        </IconButton>
        <h2>Favorites</h2>
    </div>
   <Flavloader/>
</Drawer>

<Grid item xs={2} sm={4} md={4}>
<Card sx={{  borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', padding: 0 }}>
        <Box>
        <img src={pastries} onClick={() => setPastriesDrawerOpen(true)} alt='beans' style={{ display: 'block', margin: '0 auto', width: 40 }} />
    </Box>
    <Box>
    <Typography variant='caption' sx={{fontWeight: 300}}>Yummy</Typography>
    </Box>
    </Card>
</Grid>
<Drawer anchor="right" open={pastriesDrawerOpen} onClose={() => setPastriesDrawerOpen(false)}>
    {/* Content for the Pastries drawer */}
    <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <IconButton edge="start" color="inherit" onClick={() => setPastriesDrawerOpen(false)}>
            <ArrowBackIcon />
        </IconButton>
        <h2>Pastries</h2>
    </div>
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Your Favorite Pastries
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                0
              </Typography>
              <Chip label="View History" color="primary" sx={{ marginTop: 2 }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
</Drawer>

<Grid item xs={2} sm={4} md={4}>
<Card sx={{  borderRadius: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', padding: 0 }}>
        <Box>
        <IconButton src={reward} alt='beans' onClick={() => setRewardsDrawerOpen(true)} style={{ display: 'block', margin: '0 auto', width: 40 }} >
          <CircleNotifications fontSize='large' />
        </IconButton>

    </Box>
    <Box>
    <Typography variant='caption' sx={{fontWeight: 300}}>Gear</Typography>
    </Box>
        
    </Card>
</Grid>
<Drawer anchor="right" open={rewardsDrawerOpen} onClose={() => setRewardsDrawerOpen(false)}>
    {/* Content for the Rewards drawer */}
    <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <IconButton edge="start" color="inherit" onClick={() => setRewardsDrawerOpen(false)}>
            <ArrowBackIcon />
        </IconButton>
        <h2>Koffie Gear</h2>
        
        
    </div>
    <Container maxWidth="md" sx={{ marginTop: 1 }}>
      {/* User Details */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
     {data && data?.userprof?._id ? (
  
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
      <Card sx={{ padding: 2, marginBottom: 2 }}>
        <CardContent>
        <Avatar sx={{ width: 56, height: 56, fontSize: 32, marginBottom: 2 }}>
         🌟

                </Avatar>
        <Typography variant="h6"> {data.userprof.Nickname}'s Rewards</Typography>
        <Typography variant="h4">{data.userprof.beanCount}</Typography>
        </CardContent>
        </Card>
      </Grid>
    </Grid>
   
    
  
) : null}
 </Box>

     
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Your Bean Rewards 
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                
              </Typography>
              <Chip label="View History" color="primary" sx={{ marginTop: 2 }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Reward Categories */}
      <Typography variant="h4" sx={{ marginTop: 4, marginBottom: 2 }}>
        Explore Rewards
      </Typography>
      <Grid container spacing={2}>
        {rewardCategories.map((category, index) => (
          <Grid item key={index} xs={12} md={4}>
            <Card>
              <CardContent>
                <Avatar sx={{ width: 56, height: 56, fontSize: 32, marginBottom: 2 }}>
                  {category.icon}
                </Avatar>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {category.title}
                </Typography>
                <Typography color="textSecondary">{category.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    {/* Add more content related to Rewards */}
</Drawer>


        
        </Grid>

        </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
       <Box sx={{  paddingBottom: 2, mb: 10 }}>
       <StoreAdd/>
       </Box>
    </Box>
    
  );
}
