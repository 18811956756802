import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Card, Avatar, Typography, Tooltip,
Divider, IconButton, Popover
} from '@mui/material';
import { gql } from '@apollo/client';
import { motion } from 'framer-motion';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale } from 'chart.js/auto'; 
import moment from 'moment';
import { registerAdapterDateFns } from 'chartjs-adapter-moment'; 
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import Progress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { UserContext } from '../../context/usercontext';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import kcoin from '../../images/kcoin.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GaugeChart from 'react-gauge-chart'
import Flavloader from './flavloader';
import CloseIcon from '@mui/icons-material/Close';

import Chron from '../../pages/chron';
import PriceTicket from '../finance/prods/pricefeed';
import BeanCard from './beancard';
import GoalCard from '../finance/adds/goalcard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const coffeebeans = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';
const minted = 'https://storage.googleapis.com/app_darkendimg/assets/kbean.png';
const koffie = 'https://storage.googleapis.com/app_darkendimg/assets/cherrybranch.png';

const useStyles = makeStyles((theme) => ({
  progressAndChart: {
    display: 'flex', 
  },
}));

ChartJS.register(CategoryScale);

function formatBeanCount(number) {
  if (number < 1000) {
    return number;
  } else if (number < 1000000) {
    return (number / 1000).toFixed(1) + 'k';
  } else {
    return (number / 1000000).toFixed(1) + 'M';
  }
}

const GET_TOP_USERS = gql`
  query GetTopUsers {
    userprofs {
      flavorprofile
      Nickname
      tasteProfile
      description
      name
      purchaseLink
      Koffie_story
      usertype
      _id
      country
      beanCount 
      imageurl
    }
  }
`;

export const GET_LIKES = gql`
 query GetRewards($limit: Int) {
  likes(limit: $limit) {
    _id
    likecount
  }
}
`;

const BeanTracker = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl, _id } = user.customData
  const classes = useStyles();

  const { data, loading, error } = useQuery(GET_TOP_USERS, {
    fetchPolicy: "network-only",
  });

  const { data: likesData, loading: loadingLikes, error: errorLikes } = useQuery(GET_LIKES, {
    variables: { limit: 100000 },
    fetchPolicy: "network-only",
  });

  // State for totals
  const [totalBeanCount, setTotalBeanCount] = useState(0);
  const [totalLikeCount, setTotalLikeCount] = useState(0);

  useEffect(() => {
    // Compute totalBeanCount after user data loads
    if (!loading && !error && data) {
      const sumOfBeanCounts = data.userprofs.reduce((acc, user) => acc + (user.beanCount || 0), 0);
      setTotalBeanCount(sumOfBeanCounts);
      console.log(`Total Bean Count: ${sumOfBeanCounts}`);
    }
  }, [loading, error, data]);
  console.log('Total Bean Count:', totalBeanCount);

  useEffect(() => {
    // Compute totalLikeCount after likes data loads
    if (!loadingLikes && !errorLikes && likesData) {
      const sumOfLikeCounts = likesData.likes.reduce((total, like) => total + (like.likecount || 0), 0);
      setTotalLikeCount(sumOfLikeCounts);
      console.log(`Total Like Count: ${sumOfLikeCounts}`);
    }
  }, [loadingLikes, errorLikes, likesData]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState('');

  const handleOpenPopover = (event, breadcrumbText) => {
    setAnchorEl(event.currentTarget);
    setBreadcrumb(breadcrumbText);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setBreadcrumb('');
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  // Wait until both queries are done loading before computing inAppbeans
  if (loading || loadingLikes) return <Flavloader/>;
  if (error) return <Typography>Error: {error.message}</Typography>;
  if (errorLikes) return <Typography>Error: {errorLikes.message}</Typography>;

  // Once we have totalBeanCount and totalLikeCount, compute inAppbeans
  const inAppbeans = totalBeanCount + totalLikeCount;
  console.log('In-App Beans:', inAppbeans);
  console.log('Total Likes:', totalLikeCount);              

  const { userprofs: topUsers } = data;
  
  const topUsersCopy = [...topUsers];
  topUsersCopy.sort((a, b) => b.beanCount - a.beanCount);
  const topFiveUsers = topUsersCopy.slice(0, 100);
  
  const tokens = 91520000;
  const progressPercent = (inAppbeans / tokens) * 100; 
  const availabletokens = tokens - totalBeanCount;

  const items = [
    {
      value: "23K",
      label: "Goal",
      tooltip: "This represents our goal",
      breadcrumb: "Our target weight for production.",
    },
    {
      value: formatBeanCount(availabletokens),
      label: "Available",
      tooltip: "Tokens available for use",
      breadcrumb: "Tokens ready to be used or redeemed.",
    },
    {
      value: formatBeanCount(inAppbeans),
      label: "Circulating",
      tooltip: "Total beans currently circulating",
      breadcrumb: "Beans actively in circulation.",
    },
    {
      value: `${progressPercent.toFixed(2)}%`,
      label: "Progress",
      tooltip: "Your current progress towards the goal",
      breadcrumb: "The percentage of our goal achieved.",
    },
  ];

  const sortedUsers = topFiveUsers.sort((a, b) => a.beanCount - b.beanCount);
 
  
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 0,
          borderRadius: 0,
          boxShadow: 0,
          marginTop: 0,
          position: 'relative',
         
          zIndex: 1,
          overflow: 'hidden',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          padding: 0,
        }}>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            style={{ display: 'flex', flexDirection: 'row', gap: 6 }}
          >
            {items.map((item, index) => (
              <motion.div key={index} variants={cardVariants}>
                <Tooltip title={item.tooltip}>
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 1,
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: 600, }}
                    >
                      {item.value}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 300 }}
                      >
                        {item.label}
                      </Typography>
                      <IconButton
                        onClick={(e) => handleOpenPopover(e, item.breadcrumb)}
                        size="small"
                        sx={{ marginTop: 0 }}
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Card>
                </Tooltip>
              </motion.div>
            ))}
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box sx={{ padding: 2, maxWidth: 300 }}>
                <Typography variant="body2" sx={{ fontStyle: 'italic',  }}>
                  {breadcrumb}
                </Typography>
              </Box>
            </Popover>
          </motion.div>
        </Box>
        
      </Box>
    </>
  );
};

export default BeanTracker;
