import React from 'react';
import {  Typography,  Paper, CardMedia, Card, Grid, Avatar, CardContent,  CardActionArea ,CardActions, DialogContent, Dialog, Slide, useMediaQuery,
 Button, List, Icon, Box, Chip, Snackbar, Alert, Modal} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import congo from '../../../images/congobag.png';
import { styled } from '@mui/material/styles';
import { gql,  useQuery , useMutation, useLazyQuery} from '@apollo/client';
import { useParams } from 'react-router-dom'
import Progress from '../../mods/progress';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import { UserContext } from '../../../context/usercontext';
import { useContext, useState, useEffect } from 'react';
import { BagContext } from '../../../context/BagContext';
import { Link } from 'react-router-dom';
import { Clear, SearchOffOutlined } from '@mui/icons-material';
import '../../../context/espresso.css'
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import { useTheme } from '@mui/material/styles';
import ReactImageMagnify from 'react-image-magnify'; // Import the magnifier
import Close from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';




function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const GET_PROD = gql`
  query Prod ($_id: ObjectId!)  {
  product (query: {_id: $_id}) {
   name
    price
    description1
    description2
    description3
    description4
    category
    imageurl
    imageurl1
    imageurl2
    imageurl3
    imageurl4
    farmer
    _id
   
  
  }
}
`; 

const UPDATE_CART_MUTATION = gql`
mutation UpdateOneCart($query: CartQueryInput, $set: CartUpdateInput!) {
  updateOneCart(query: $query, set: $set) {
    userId
    isPaid
    category
    items {
      productId
      quantity
      options {
        size
        color
        roast
      }
    }
  }
}
`;

const UPSERT_CART_MUTATION = gql`
mutation UpsertOneCart($query: CartQueryInput, $data: CartInsertInput!) {
  upsertOneCart(query: $query, data: $data) {
    userID
    cart {
      productId
      category
      quantity
      options {
        size
        color
      }
    }
  }
}

`;

// Insert one cart insertOneCart(data: CartInsertInput!): Cart

export const INSERT_CART_MUTATION = gql`
mutation InsertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data) {
    userID
    productId
    price
    isPaid
    beanies
    name
    category
    quantity
    options {
      size
      color
    }
  }
}
`;


export const GET_ROASTERIES = gql`
  query GetImage ($_id: ObjectId!){
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
      Nickname
      Koffie_story
    }
  }
`;

export const GET_CART_ITEMS = gql`
  query GetCart($userID: ObjectId!) {
    cart(query: { userID: $userID }) {
      userID
      productId
      isPaid
      category
      quantity
      options {
        size
        color
      }
    }
  }
`;

const Item = styled(Paper)(({ theme }) => ({
	
	padding: 1,
	textAlign: 'center',
  
	boxShadow: 'none'
  }));

const useStyles = makeStyles(() => ({
  root: {
	padding: '10px',
   
    borderRadius: 20,

      transition: '0.3s',
      backgroundImage: `url(${''})`, // Replace with the URL of your image
    backgroundSize: 'cover', // Cover the entire card with the image
    backgroundRepeat: 'no-repeat', // Do not repeat the image
    backgroundPosition: 'center', // Center the image
     
  },
  content: {
    padding: 10,
    spacing: 8,
  },
  brandCardHeader: {
    // Add styles for BrandCardHeader here
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'left',
	margin: '0px' /* or a smaller value than what's currently set */
	
    
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  button: {
    // Add styles for the button here
    backgroundColor: '#077336',
    color: 'white',
    borderRadius: 20,
    padding: 10,
    border: 'none',
    width: '40%',
    height: 40,
    marginTop: 10,
    '&:hover': {
      backgroundColor: '#077336',
    },
    
  },
}));

const kupakoffie = () => {
  return {
    "name": "Barista",
    "description": "Give info based on available data.",
    "parameters": {
      "type": "object",
      "properties": {
        "Title": {
          "type": "string",
          "description": "Title of Subject"
        },
        "price": {
          "type": "string",
          "description": "do not output dollar sign. Integer Only."
        },
        "description": {
          "type": "string",
          "description": "Tell a short tale of the events in what happened."
        },
        "productID": {
          "type": "string",
          "description": "The product ID"
        },

          "primaryImage": 
          {"type": "string",
          "description": "The url of the primary image"
        
        },
        "imageurl":
        {"type": "string",
        "description": "The URL of userimage"
      },
     

      
        "When": 
          {"type": "string",
          "description": "When is this available"
        
        },

        "userID":
        {"type": "string",
        "description": "The user ID"
      }, 
      "imageurl1": {
        "type": "string",
        "description": "The URL of the first image"
      },
      "imageurl2": {
        "type": "string",
        "description": "The URL of the second image"
      },
      "imageurl3": {
        "type": "string",
        "description": "The URL of the third image"
      },
      "imageurl4": {
        "type": "string",
        "description": "The URL of the fourth image"
      },
      "sales_pitch": {
        "type": "string",
        "description": "A compelling sales pitch based on the product description."
      }
      
        
      },
      
      "required": ["name", "Title", "description",  "primaryImage", "When",   "price", "productID", "userID", "imageurl", "imageurl1", "imageurl2", "imageurl3", "imageurl4", "sales_pitch"]
    }
  };
};


const Buyit = ({ onAddToBag }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);
    const classes = useStyles();
    const { _id } = useParams()
    const [isClicked, setIsClicked] = useState(false);
    const { itemsInBag, setItemsInBag } = useContext(BagContext);
    const [showDialog1, setShowDialog1] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [descriptionData1, setDescription] = useState(''); // New state for When
  const [content, setOutput] = useState('');
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  //Add Option to the Cart
  const [options, setOptions] = useState({ size: "M", color: "Red" });
  const [quantity, setQuantity] = useState(1);
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");  // Default size
  const [selectedColor, setSelectedColor] = useState("");  // Default color
  const [selectedRoast, setSelectedRoast] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleOpenModal = (product) => {
    setSelectedProduct(product); // Set the clicked product as the selected product
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null); // Clear the selected product when closing the modal
    setOpenModal(false);
  };

  // Handle the dropdown changes
  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };


  const handleRoastChange = (event) => {
    setSelectedRoast(event.target.value);
  };
  
  // Add a drop down to select the quantity
  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  

  const handleOpenGear = () => {
    setShowDialog(true);

  }
  const handleCloseGear = () => {
    setShowDialog(false);
  }

  const handleOpen1 = () => {
    setShowDialog1(true);
  }
  const handleClose1 = () => {
    setShowDialog1(false);
  }

  // Modify handleOpen to set the selected image URL
const handleOpen = (imageUrl) => {
  setOpen(true);
  setSelectedImageUrl(imageUrl);
};

// Modify handleClose to reset the selected image URL
const handleClose = () => {
  setOpen(false);
  setSelectedImageUrl(null);
};

const [loadDescription, { data: descriptionData }] = useLazyQuery(GET_PROD, {
  fetchPolicy: "network-only",
  variables: { // Update based on expected structure of GET_AI_PROD_QUERY
    _id: _id,
    
    
  },
});
const handleShowDescription = (productId) => {
  loadDescription({
   
    variables: { _id: productId },
  });
};

useEffect(() => {
  if (descriptionData && descriptionData.product) {
    setDescription(descriptionData);
  }
  
}, [descriptionData]);
console.log(descriptionData1);
    const { loading, data, error } = useQuery(GET_PROD, {
        variables: { _id: _id }
      });
     
      const [upsertCart, ] = useMutation(UPSERT_CART_MUTATION);
      const [updateCart, ] = useMutation(UPDATE_CART_MUTATION);
      const [insertCart, ] = useMutation(INSERT_CART_MUTATION);
 
    if (loading) return <p><Progress/></p>;
if (error) return <p>Error: {error.message}</p>;

 const product = {
    id: data.product._id, 
    name: data.product.name,
    price: data.product.price,
    image: data.product.imageurl, // adjust this according to your schema
    description1: data.product.description1,
    description2: data.product.description2,
    description3: data.product.description3,
    description4: data.product.description4,
    farmer: data.product.farmer,
    category: data.product.category,
    imageurl1: data.product.imageurl1,
    imageurl2: data.product.imageurl2,
    imageurl3: data.product.imageurl3,
    imageurl4: data.product.imageurl4,
    beanies: data.product.beanies,
    
};

const handleClick = () => {
  setIsClicked(true);
  // Reset the animation state after a short delay
  setTimeout(() => setIsClicked(false), 300);
};

//add 4000 to the cart only for desired category



  


const handleAddToCart3 = async (product) => { 
  handleClick();
  const beaniesToAdd = product.category === 'Koffie'  ? 4000 : 0;
  const cartData = {
    query: { userID: user.id },  // Assuming user.id is securely fetched
    data: {
      userID: user.id,  // Possibly redundant; ensure alignment with your backend logic
      productId: product.id,
      quantity: 1,
      isPaid: 'false',
      name: product.name,
      beanies: beaniesToAdd,
      category: product.category,
      price: product.price,
      imageurl: product.image,  // Adjust this according to your schema
      options: {  // Options need to be nested within each cart item
        size: selectedSize,  // Use selected size
          color: selectedColor , // Use selected color
          roast: selectedRoast
      }
    }
  };

  try {
    await insertCart({
      variables: cartData
    });
   
    onAddToBag(product);  // UI feedback action
    setSnackbarOpen(true); // Show the snackbar
  } catch (error) {
    console.error("Error inserting cart:", error);
    // Optionally handle error in the UI
  }
};


const handleAddToCart2 = async (product, quantity = 1, options = { size: "M", color: "Red" }) => {
  const cartData = {
    query: { userID: user.id },  // Assuming user.id is securely fetched
    data: {
      userID: user.id,  // Possibly redundant; ensure alignment with your backend logic
      cart: [{
        productId: _id,
        quantity: quantity,
        options: {  // Options need to be nested within each cart item
          size: options.size,
          color: options.color
        }
      }]
    }
  };

  try {
    await upsertCart({
      variables: cartData
    });
    onAddToBag(product);  // UI feedback action
  } catch (error) {
    console.error("Error upserting cart:", error);
    // Optionally handle error in the UI
  }
};




const handleAddToCart = async (product) => {
  const cartUpdate = {
    query: { userId: user.id }, // Replace with actual user ID logic
    set: {
      items: [{
        productId: product.id,
        quantity: 1, // Or dynamic quantity
        options: {
          size: "M", // These should be dynamic or removed if not used
          color: "Red"
        }
      }]
    }
  };
  
  try {
    await updateCart({
      variables: cartUpdate
    });
    onAddToBag(product);
  } catch (error) {
    console.error("Error updating cart:", error);
  }
};



//AI Sales Person


const handleSubmit = (product, ) => {

  // Get the product data based on the ID
  

  
  
  setIsLoading(true);  // Start loading before the fetch request
  // Call the lazy query to fetch the product description
  handleShowDescription(product._id);
  // Fetch the product description using the lazy query
 
  // Reset the clickedItems array after the fetch request is complete


  const coffeeFunction = kupakoffie();  // Get the coffee function
  
  fetch('https://api.openai.com/v1/chat/completions', { // replace with your server URL
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${"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"}`,

    },
    body: JSON.stringify({
      
      "messages": [
           
        {"role": "system", "content": "You are the world's best sales agent. With the best sense of humer. based on the Item information, sell it to get Rich. Do not output dollar sign on price, integer only :)" },
        {
          "role": "user",
          "content": `Here is a product description: "${JSON.stringify(data)}". Your task is to turn it into an engaging and persuasive sales pitch. Highlight its unique features and why customers absolutely need it! Keep it fun and compelling. 
        
        Important: Prices are listed in cents, so be sure to divide by 100 and format them to two decimal places (e.g., 2900 -> 29.00, 2998 -> 29.98). Also, include a unique title for the product.`
        }
        
        
        ],
          
          "temperature": 0.5,
          'model': 'gpt-4o',
           'functions': [coffeeFunction],
            "function_call": {
              "name": "Barista",
              "arguments": JSON.stringify(product),
            },
     
      
      
    }),
  })
  .then(response => response.json())
  .then(data => {
    let contentData;
    if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
      contentData = {
        
        When: data.choices[0].message.content.When,
        Title: data.choices[0].message.content.Title,
        price: data.choices[0].message.content.price,
        description: data.choices[0].message.content.sales_pitch,
        primaryImage: data.choices[0].message.content.primaryImage,
        productID: data.choices[0].message.content.productID,
        userID: data.choices[0].message.content.userID,
        imageurl: data.choices[0].message.content.imageurl,
        imageurl1: data.choices[0].message.content.imageurl1,
        imageurl2: data.choices[0].message.content.imageurl2,
        imageurl3: data.choices[0].message.content.imageurl3,
        imageurl4: data.choices[0].message.content.imageurl4,
        
        
      };
      
    } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
      contentData = JSON.parse(data.choices[0].message.function_call.arguments);
    }
    

    setOutput(contentData); // Render the content on the frontend
    setShowDialog1(true); 
    console.log('Success:', contentData);
    console.log('Total tokens used:', data.usage.total_tokens);
    console.log('Success:', data);

    // Construct the variables for the GraphQL mutation
    const output = {
      data: contentData,
      _id: user.id
      
    };

    // Perform the GraphQL mutation using the 'output' object
 
   
    setIsLoading(false); // End loading after receiving the response
  })
  .catch(error => {
    console.error('Error:', error);
    setIsLoading(false); // End loading if there's an error
  });
    
};


  return (
    
    <Stack spacing={2} sx={{paddingTop: 0}} >
      
      
        <Card  sx={{paddingTop: 0}}>
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', padding: 0 }}>
      <IconButton onClick={() => window.history.back()} >
        <ArrowBackIos />
      </IconButton>
     <Typography variant='body1' sx={{ textAlign: 'center', fontWeight: 700, color: 'text.secondary', mt: 1 }}>
      {product.name}
      </Typography>
      </Box>

<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
<Grid item xs={12}>
          <Item >
          <Box 
   onClick={() => handleOpen(selectedImageUrl || product.image)}
  sx={{ 
    display: 'flex',
    
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundImage: `url(${selectedImageUrl || product.image})`, // Use the selected image URL or the default image
    backgroundSize: 'contain', // Image will be fully visible but may leave white space
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: 0,
    height: '50vh',
    width: '100%',
    padding: 0,
    transition: '0.3s',
    margin: 0,
    position: 'relative',
  }}
/>
  
 

{/* Add the Add to Cart button */}

<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 1, paddingBottom: 0, paddingLeft: 1 }}>
<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
    {/* Conditionally render each thumbnail only if the image URL exists */}
    {product.imageurl1 && (
      <Box onClick={() => setSelectedImageUrl(product.imageurl1)}>
        <img src={product.imageurl1} alt="thumbnail 1" width={50} />
      </Box>
    )}
    {product.imageurl2 && (
      <Box onClick={() => setSelectedImageUrl(product.imageurl2)}>
        <img src={product.imageurl2} alt="thumbnail 2" width={50} />
      </Box>
    )}
    {product.imageurl3 && (
      <Box onClick={() => setSelectedImageUrl(product.imageurl3)}>
        <img src={product.imageurl3} alt="thumbnail 3" width={50} />
      </Box>
    )}
    {product.imageurl4 && (
      <Box onClick={() => setSelectedImageUrl(product.imageurl4)}>
        <img src={product.imageurl4} alt="thumbnail 4" width={50} />
      </Box>
    )}
  </Box>
   
</Box>

<Dialog 
      open={open} 
      onClose={handleClose} 
      
      sx={{ padding: 0 }}
    >
      <Box sx={{ position: 'relative', height: 'auto', width: '100%' }}>
        {/* Close button to close the dialog */}
        <IconButton 
          onClick={handleClose} 
          sx={{ position: 'absolute', top: 10, right: 10, zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
        >
          <Close />
        </IconButton>
        
        {/* Magnifier for the image */}
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: 'Product Image',
              isFluidWidth: true,
              src: selectedImageUrl, // Small version of the image
            },
            largeImage: {
              src: selectedImageUrl, // High-resolution version of the image
              width: 1200, // You can adjust the size of the large image
              height: 800, // You can adjust the size of the large image
            },
            enlargedImageContainerDimensions: {
              width: '150%', 
              height: '150%',
            },
            style: { objectFit: 'cover' },
          }}
        />
         
          <Typography variant='caption'sx={{ position: 'absolute', bottom: 10, left: 10, zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }} >
            Click image to magnify
            </Typography>
      </Box>
    </Dialog>
		  </Item>
        </Grid>

        <Grid item xs={12}>
  <Item>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 1 }}> {/* Flexbox to align items and distribute space */}
      <Typography variant='body1' sx={{ textAlign: 'left', padding: 0, margin: 0, fontWeight: 600, }}>  {/* Reduced padding and lineHeight */}
        {product.name} - 1lb Bag
      </Typography>
      <div style={{  }}>
    
    <Typography variant="h6" color="text.secondary" fontWeight="600">
  ${(product.price / 100).toFixed(2)}
</Typography>

   
  </div>
      <Box  onClick={() => handleOpenModal(product)} // Pass the correct product to the modal
       sx={{ display: 'flex', alignItems: 'center', margin: 0, padding: 0 ,  }}>  {/* Removed extra padding/margin */}
        <Typography variant='caption' sx={{ marginRight: '4px', fontWeight: 600, padding: 0 }}>  {/* Removed padding/margin */}
          4k DigiBeans
        </Typography>
       
        <img 
          src={product.description2}
          alt={product.name} 
          style={{ width: '40px', height: '40px', marginBottom: 10 }}
          
        />
      </Box>
    </Box>
  </Item>
</Grid>

{selectedProduct && (
       <Modal
       open={openModal}
       onClose={handleCloseModal}
       sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}
       BackdropProps={{
         style: { backgroundColor: 'transparent' }, // Set the backdrop to transparent
       }}
     >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',  // Dark mode: dark background, Light mode: white background
              padding: 2,
              borderRadius: 5,
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              boxShadow: 3,
              border: 'solid 1px',
            }}
          >
            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 600, mb:1 }}>
              Earn 4k DigiBeans with this purchase
            </Typography>
            <img
              src={selectedProduct.description2}
              alt="bean head"
              style={{ width: 100, height: 100, borderRadius: 50 }}
            />
            <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 500, mt: 1 }}>
              Use to Post, Like, Share, Sell products and exchange and redeem for discounts
            </Typography>
          </Box>
        </Modal>
      )}


     
      
      {/* Conditional rendering based on the category */}
      
      {product.category === 'Gear' ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  paddingLeft: 2, paddingRight: 2, paddingTop: 0, paddingBottom: 0, width: '100%' }}>
          {/* Size Dropdown */}
          
          <Typography variant="body1" sx={{  marginTop: 0,fontWeight: 600 }}>Select Size</Typography>
          <Select size='small' value={selectedSize} onChange={handleSizeChange} >
            <MenuItem value="S">Small</MenuItem>
            <MenuItem value="M">Medium</MenuItem>
            <MenuItem value="L">Large</MenuItem>
            <MenuItem value="XL">Extra Large</MenuItem>
          </Select>

         
        </Box>
      ) : product.category === 'Koffie' ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 2, paddingRight: 2, paddingTop: 0, paddingBottom: 0, width: '100%' }}>
          {/* Roast Dropdown */}
          <Typography variant="body1" sx={{ marginTop: 0, fontWeight: 600 }}>Select Roast</Typography>
          <Select size='small' value={selectedRoast} onChange={handleRoastChange}  sx={{lineHeight: 1, paddingTop: 0, paddingBottom: 0}}>
            <MenuItem value="Light">Light</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Dark">Dark</MenuItem>
          </Select>
        </Box>
      ) : null}
    
    <Grid item xs={12}>
  <Item 
    sx={{ 
      display: "flex", 
      justifyContent: "space-between", // Push buttons apart
      alignItems: "center", 
      gap: 2, // Space between buttons
    }}
  >
    <Button 
      onClick={() => handleSubmit(product)} 
      variant="contained" 
      sx={{ 
        backgroundColor: "#077336", 
        fontSize: "0.75rem", // Smaller text
        padding: "4px 8px", // Smaller button padding
        minWidth: "auto", // Prevent extra width
        height: "32px", // Reduce height
        "&:hover": { backgroundColor: "#055d2e" } 
      }}
    >
      Sales Assistant
    </Button>

    <Button 
      sx={{
        backgroundColor: "#077336", 
        border: "none", 
        width: "50%", // Reduce width
        height: "32px", // Smaller height
        borderRadius: 3, 
        fontSize: "0.75rem", // Smaller font size
        padding: "4px 8px", // Adjust padding
        ":hover": { backgroundColor: "#ffc107" } 
      }}
      onClick={() => handleAddToCart3(product)}
    >
      <Typography 
        variant="body2" 
        sx={{  
          textAlign: "center", 
          fontWeight: 600, 
          color: "white",
        }}
      >
        Add to Bag
      </Typography>
    </Button>
  </Item>
</Grid>

        <Grid item xs={12}>
          
            <Typography variant='body1' sx={{  textAlign: 'left', padding: 1, color: 'text.secondary' }}>
          {product.description1}
        </Typography>
        
        </Grid>
        
        {!isMobile && (
           <Box>
           {isLoading ? (
       <Dialog   open={isLoading} onClose={() => setIsLoading(false)}>
        <DialogContent sx={{ padding: 2, textAlign: 'center', mt: 10, backgroundColor: "transparent" }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',padding: 5 }}>
            <img src="https://storage.googleapis.com/app_darkendimg/assets/brewing.png" alt="Loading" style={{ width: '100%', height: 100 }} />
          </Box>
      
        </DialogContent>
        </Dialog>
    ) : (
      <>
           
      
          <div >
          {showDialog1 && (
        <Dialog
          
          open={showDialog1}
          onClose={() => setShowDialog1(false)}
          TransitionComponent={Transition}
        >    
         <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000} // Snackbar will close after 6 seconds
  onClose={() => setSnackbarOpen(false)} // Close Snackbar when dismissed
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position of the Snackbar
>
  <Alert
    onClose={() => setSnackbarOpen(false)}
    severity="success" // Use "success" for a green success message
    sx={{ width: '100%' }}
  >
    Item added, go to cart to complete transaction
  </Alert>
</Snackbar>
            <div >
              <IconButton onClick={handleClose1}>
                <Clear />
              </IconButton>
           
                  </div>
           
                  {content && (
         
       <Box sx={{ display: 'flex',  }}>
         
        <Card  sx={{ width: '100%', height: '100%', borderRadius: 5, padding: 1,  }}>
      

       
        <CardMedia
          component="img"
          sx={{ width: '100%', height: 250, borderRadius: 0, objectFit: 'cover'}}
          image={content.primaryImage}
          alt="Image description"
        /> 

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>    
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <UserImage userID={content.userID}  />

          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <FarmerImage UserID={content.userID}  />
          </Box>  
          </Box> 
           <CardContent sx={{ flexGrow: 1, boxShadow: 0, borderRadius: 5 }}>
           
                  
               
       <Typography variant='h5' >{content.Who}</Typography>
       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
            <Typography variant="h6" color="text.secondary" sx={{fontWeight: 600, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)'}}>
              {content.Title}
            </Typography>
            <Typography variant='h5' sx={{fontWeight: 900, color: '#000'}}>4K Beans <img src="https://storage.googleapis.com/app_darkendimg/assets/brkoin.png" alt="Bean" style={{ width: 30, height: 30, borderRadius: 50 }} /></Typography>
            </Box>
                <Typography variant="h6" sx={{
                  fontWeight: 300,
                  color: 'text.secondary',
                  textAlign: 'left',
                  paddingTop: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  paddingBottom: 2,
                }}>
                 
                  {content.sales_pitch}
                </Typography>
                
       
        
       <Typography>{content.purchaseLink}</Typography>
       <Link to="/bag" style={{ textDecoration: 'none' }}>
                    <Chip
                        label={`Bag (${itemsInBag.length})`}
                        className={classes.transparentChip}
                        avatar={<Avatar src='https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png' alt="Congo" />}
                    />
                </Link>
       <CardActionArea>
       
       <CardActions sx={{ justifyContent: 'flex-end'}} >
      <Typography variant='h4'sx={{fontWeight: 600, paddingRight: 2, justifyContent: 'flex-start' }}>{content.price}</Typography> 
      <AddShoppingCart onClick={() => handleAddToCart3(product)} edge="end" size="small"  sx={{ 
                                                            display: 'inline-flex', 
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            transition: 'transform 0.3s, color 0.3s',
                                                            transform: isClicked ? 'scale(1.2)' : 'scale(1)',
                                                            color: isClicked ? '#0a0' : '#077336',
                                                            cursor: 'pointer',
                                                        }}/>
  
            
        </CardActions>
        </CardActionArea>
                </CardContent>
            
        
        
      </Card>
      </Box>
     
            )}	
            </Dialog>
      )}
          </div>  
         
      </>

    )}            
           </Box>
        )}
        {isMobile && (
           <Box>
           {isLoading ? (
       <Dialog  fullScreen open={isLoading} onClose={() => setIsLoading(false)}>
        <DialogContent sx={{ padding: 2, textAlign: 'center', mt: 10, backgroundColor: "transparent" }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',padding: 5 }}>
            <img src="https://storage.googleapis.com/app_darkendimg/assets/brewing.png" alt="Loading" style={{ width: '100%', height: 100 }} />
          </Box>
        
        </DialogContent>
        </Dialog>
    ) : (
      <>
           
      
          <div >
          {showDialog1 && (
        <Dialog
          fullScreen
          open={showDialog1}
          onClose={() => setShowDialog1(false)}
          TransitionComponent={Transition}
        >   
         <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000} // Snackbar will close after 6 seconds
  onClose={() => setSnackbarOpen(false)} // Close Snackbar when dismissed
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position of the Snackbar
>
  <Alert
    onClose={() => setSnackbarOpen(false)}
    severity="success" // Use "success" for a green success message
    sx={{ width: '100%' }}
  >
    Item added, go to cart to complete transaction
  </Alert>
</Snackbar> 
            <div >
              <IconButton onClick={handleClose1}>
                <Clear />
              </IconButton>
            <Typography variant="h6" >
                    
                  </Typography>
                  </div>
           
                  {content && (
         
       <Box sx={{ display: 'flex',  }}>
        <Card  sx={{ width: '100%', height: '100%', borderRadius: 5, padding: 1,  }}>
      

       
        <CardMedia
          component="img"
          sx={{ width: '100%', height: 250, borderRadius: 0, objectFit: 'cover'}}
          image={content.primaryImage}
          alt="Image description"
        /> 

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>    
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <UserImage userID={content.userID}  />

          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <FarmerImage UserID={content.userID}  />
          </Box>  
          </Box> 
          
           <CardContent sx={{ flexGrow: 1, boxShadow: 0, borderRadius: 5 }}>
           
                  
               
       <Typography variant='h5' >{content.Who}</Typography>
       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
            <Typography variant="h6" color="text.secondary" sx={{fontWeight: 600, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)'}}>
              {content.Title}
            </Typography>
            <Typography variant='h5' sx={{fontWeight: 900, color: '#000'}}>4K Beans <img src="https://storage.googleapis.com/app_darkendimg/assets/brkoin.png" alt="Bean" style={{ width: 30, height: 30, borderRadius: 50 }} /></Typography>
            </Box>
                <Typography variant="h6" sx={{
                  fontWeight: 300,
                  color: 'text.secondary',
                  textAlign: 'left',
                  paddingTop: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                  paddingBottom: 2,
                }}>
                 
                  {content.sales_pitch}
                </Typography>
                
       
        
       <Typography>{content.purchaseLink}</Typography>
       <Link to="/bag" style={{ textDecoration: 'none' }}>
                    <Chip
                        label={`Bag (${itemsInBag.length})`}
                        className={classes.transparentChip}
                        avatar={<Avatar src='https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png' alt="Congo" />}
                    />
                </Link>
       <CardActionArea>
       
       <CardActions sx={{ justifyContent: 'flex-end'}} >
      <Typography variant='h4'sx={{fontWeight: 600, paddingRight: 2, justifyContent: 'flex-start' }}>{content.price}</Typography> 
      <AddShoppingCart onClick={() => handleAddToCart3(product)} edge="end" size="small"  sx={{ 
                                                            display: 'inline-flex', 
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            transition: 'transform 0.3s, color 0.3s',
                                                            transform: isClicked ? 'scale(1.2)' : 'scale(1)',
                                                            color: isClicked ? '#0a0' : '#077336',
                                                            cursor: 'pointer',
                                                        }}/>
  
            
        </CardActions>
        </CardActionArea>
                </CardContent>
            
        
        
      </Card>
      </Box>
     
            )}	
            </Dialog>
      )}
          </div>  
         
      </>

    )}            
           </Box>
        )}
           
          <Grid item xs={6}>
        <Item >
      <div style={{ borderRadius: 5, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: '4px', border: 'solid 1px'  }}>
      {/* Place text and chip at the bottom */}
     
      <div style={{ display: 'flex', alignItems: 'center' }}>
       <Typography variant="caption"  sx={{fontWeight: 700}}>
        Roast Master
      </Typography>
      
      </div>

      <UserImage userID={product.description4}  />
      </div>

    </Item>
    
     
        </Grid>
        <Grid item xs={6}>
        
    <Item >
      <div style={{borderRadius: 5, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: '4px', border: 'solid 1px'  }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
       <Typography variant="caption"  sx={{fontWeight: 700}}>
        Farmer
      </Typography>
      </div>
      
      <UserImage userID={product.farmer}  />
      
    </div>
      </Item>
     
        </Grid>
          
		</Grid>

	
    
</Card>
<Grid item xs={12}>
          <Item >
          <Button 
  sx={{
    backgroundColor: "#077336", border: 'none', width: '60%', height: 50, borderRadius: 5, 
    ':hover': { backgroundColor: '#ffc107' } // Corrected hover effect
  }}
  onClick={() => handleAddToCart3(product)}
>
  <Typography 
    variant='body1' 
    sx={{  
      textAlign: 'center', 
      fontWeight: 600, 
      color: 'white',
    }}
  >
    Add to Bag
  </Typography>
</Button>

     
      </Item>
      </Grid>
</Stack>
  );

  
}

// Component to fetch and display </></> image
function UserImage({ userID }) {
  const { loading, error, data } = useQuery(GET_ROASTERIES, {
      variables: { _id: userID },
  });

  if (loading) return <Avatar src="https://storage.googleapis.com/app_darkendimg/assets/roasterbean.webp" alt="user" style={{ width: 60, height: 60,  }} />;
  if (error) return <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, borderBlock: 1,  borderRadius: 5, }}>
        
  <img src="https://storage.googleapis.com/app_darkendimg/assets/roasterbean.webp" alt="user" style={{ width: 40, height: 40, borderRadius: 50,  }} />
    <CardContent sx={{padding: 0, margin: 0, }}>
      <Typography variant="caption" fontWeight={400}>
        Coming Soon - Roast Master
      </Typography>
      </CardContent>
      </Card>;
  const userimage = data.userprofs[0]?.imageurl || 'Unknown';
  const username = data.userprofs[0]?.Nickname || 'Unknown';
  const userstory = data.userprofs[0]?.Koffie_story || 'Unknown';



  return (
    <>
    
    <Box display="flex" >
    
      <Avatar src={userimage} alt={username} style={{ width: 60, height: 60, borderRadius: 50 }} />
     
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', }}>
      <Typography variant="caption"  sx={{ paddingLeft: 1 , fontWeight: 700, alignContent: 'left', textAlign: 'left' }}>
        {username}
      </Typography>
     
      <Typography variant="caption" color="text.secondary" sx={{ paddingLeft: 1 , }}>
        {userstory}
      </Typography>
      </Box>
    </Box>
    
    </>
    
  );
          
}

// Component to fetch and display </></> Farmer image
function FarmerImage({ userID }) {
  const { loading, error, data } = useQuery(GET_ROASTERIES, {
      variables: { _id: userID, },
  });
  console.log(data);

  if (loading) return <img src="https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png" alt="user" style={{ width: 60, height: 60, borderRadius: 50 }} />;
  if (error) return <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, borderBlock: 1, borderRadius: 5, }}>
         
  <img src="https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png" alt="user" style={{ width: 40, height: 40, borderRadius: 50 }} />
    <CardContent>
      <Typography variant="caption" fontWeight={400}>
        Coming Soon - Farmer
      </Typography>
      </CardContent>
      </Card>;

  const farmerimage = data.userprofs?.imageurl || 'Unknown';
  const farmername = data.userprofs?.Nickname || 'Unknown';
  const farmerstory = data.userprofs?.Koffie_story || 'Unknown';

  

  return (
    <>
    
    <Box display="flex" >
    
      <Avatar src={farmerimage} alt={farmername} style={{ width: 60, height: 60, borderRadius: 50 }} />
     
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', }}>
      <Typography variant="caption"  sx={{ paddingLeft: 1 , fontWeight: 700, alignContent: 'left', textAlign: 'left' }}>
        {farmername}
      </Typography>
     
      <Typography variant="caption" color="text.secondary" sx={{ paddingLeft: 1 , }}>
        {farmerstory}
      </Typography>
      </Box>
    </Box>
    
    </>
    
  );
          
}


  
export default Buyit;
