import { useLazyQuery, useMutation, useQuery, gql } from '@apollo/client';
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../context/usercontext';


export const useItem = (userID) => {
    const { user } = useContext(UserContext);
    const [insertOneProduct] = useMutation(INSERT_ONE_PRODUCT);
  const [updateOneProduct] = useMutation(UPDATE_ONE_PRODUCT);
  const [deleteOneProduct] = useMutation(DELETE_ONE_PRODUCT);
  // Apollo Client Queries and Mutations
  const { loading, data, error, refetch } = useQuery(GET_USER_PROD_QUERY, {
    variables: { userID: user.id },
    fetchPolicy: 'cache-and-network',  // Add this line
    
  });


  
 
  
  // Local States
  const [beans, setBeans] = useState(0);
  const [products, setItems] = useState([]);

  // Load goals initially or whenever userID changes
  useEffect(() => {
    if (data && data.products) {
      setItems(data.products);
    }
  }, [data]);

  const handleInsertProduct = async (newProduct) => {
    const result = await insertOneProduct({ variables: { data: newProduct } });
    await refetch();
    return result;
  };
  

  const handleUpdateProduct = (product) => {
    const { __typename, ...productData } = product;
    updateOneProduct({
      variables: {
        query: { _id: productData._id },
        set: productData
      }
    }).then(() => {
      refetch();
    });
  };

  const handleDeleteProduct = (productId) => {
    deleteOneProduct({
      variables: {
        query: { _id: productId }
      }
    }).then(() => {
      refetch();
    });
  };
  

  return {
    loading,
    error,
    products: data?.products || [],
    handleInsertProduct,
    handleUpdateProduct,
    handleDeleteProduct,
    refetch,
  };
};

// Define Apollo Client queries and mutations here
const GET_USER_PROD_QUERY = gql`
  query GetUserGoals($userID: String!) {
  products(query: {userID: $userID}) {
    
    _id
    userID
    name
    price
    description1
    imageurl
    
  }
}


`;



const INSERT_ONE_PRODUCT = gql`
  mutation InsertOneProduct($data: ProductInsertInput!) {
    insertOneProduct(data: $data) {
      _id
      userID
      name
      price
      description1
      imageurl
      imageurl1
      imageurl2
      imageurl3
      imageurl4
      prodowner
    }
  }
`;

const UPDATE_ONE_PRODUCT = gql`
  mutation UpdateOneProduct($query: ProductQueryInput, $set: ProductUpdateInput!) {
    updateOneProduct(query: $query, set: $set) {
      _id
    }
  }
`;

const DELETE_ONE_PRODUCT = gql`
  mutation DeleteOneProduct($query: ProductQueryInput!) {
    deleteOneProduct(query: $query) {
      _id
    }
  }
`;




