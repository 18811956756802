import { UserContext } from "../context/usercontext";
import { Button, TextField, MenuItem, FormControl, InputLabel, Select } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GoogleOneTap from "../components/mods/googauthserver";
import { red } from "@mui/material/colors";


const BlockchainLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [customData, setCustomData] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const { user, fetchUser, emailPasswordLogin, setUser } = useContext(UserContext);

    const [form, setForm] = useState({
      email: "",
      password: "",
      userType: ""
    });
  
    const onFormInputChange = (event) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };
  
    const loaduser = async () => {
      if (!user) {
        const fetchedUser = await fetchUser();
        if (fetchedUser) {
          redirectNow();
        }
      }
    }
  
    
  
    
  
    const onSubmit = async () => {
      try {
        const authedUser = await emailPasswordLogin(form.email, form.password);
        setCustomData(authedUser.customData);
        setUser(authedUser); // Set the authenticated user directly
        redirectNow();
      } catch (error) {
        console.error('Login failed:', error);
        setLoginError("Invalid username or password");
      }
    };
    
    
    const redirectNow = () => {
      const redirectTo = location.search.replace("?redirectTo=", "");
      navigate(redirectTo ? redirectTo : "/");
    };
    
    
    

    return (
      <div style={{ display: "flex", flexDirection: "column", maxWidth: "300px", margin: "auto" }}>
      <form style={{ display: "flex", flexDirection: "column", maxWidth: "300px", margin: "auto" }}>
        <h1>Login</h1>
        {loginError && (
        <p style={{ color: red[500] }}>
          {loginError} <Link to="/reset-password">Forgot Password?</Link>
        </p>
      )}
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          name="email"
          value={form.email}
          onChange={onFormInputChange}
          style={{ marginBottom: "1rem" }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          name="password"
          value={form.password}
          onChange={onFormInputChange}
          style={{ marginBottom: "1rem" }}
        />

        

        <Button variant="contained"
          color="primary"
          onClick={onSubmit}
          style={{ marginTop: "2rem" }}>
          Login
        </Button>

        <p>
          Don't have an account? <Link to="/signup">Signup</Link> 
          <br/>
          Forgot password? <Link to="/reset-password">Reset Password</Link>
        </p>
        <div>
          ..Or
          
          </div>

      </form>
      </div>
  );
}

export default BlockchainLogin;
